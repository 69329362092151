<template>
  <div class="objects position-relative">

    <div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative">
      <div class="page-header d-flex align-content-center align-items-center">
        <h1 v-if="this.object_id" class="page-title mb-0 me-auto" v-text="this.title['edit']" />
        <h1 v-else class="page-title mb-0 me-auto" v-text="this.title['default']" />
        <a href="javascript:{}"
           class="d-inline-flex align-items-center ms-auto me-0"
           @click="this.goBack()">
          <span aria-label="Вернуться назад"
                class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
                style="padding: 0.75rem 0.95rem;">
            <img :src="require('@/assets/icons/undo.svg')" alt="Назад" width="18" height="18">
          </span>
          <span class="text-font-light">Назад</span>
        </a>
      </div>
      <div v-if="this.is_loading_process" class="d-flex vh-60 my-0 align-items-center">
        <Loader />
      </div>
      <form v-else class="border border-brd-primary border-start-0 border-end-0 border-bottom-0 py-4 mt-3 needs-validation"
            :data-object-id="this.object_id"
            novalidate>
        <div v-if="this.user_cant_edit" class="alert alert-danger">
          <img :src="require('@/assets/icons/warning.svg')" class="d-inline-block me-1" alt="Ошибка" width="14" height="14" style="margin-top: -3px" />
          Недостаточно прав для редактирования данного Объекта.
        </div>
        <div v-if="this.object_has_lock" class="alert alert-danger">
          <img :src="require('@/assets/icons/warning.svg')" class="d-inline-block me-1" alt="Ошибка" width="14" height="14" style="margin-top: -3px" />
          Объект уже редактируется другим пользователем.
        </div>
        <div v-else>
          <div class="col">
            <ToggleButtons inputId="operation_type"
                           inputLabel="Раздел"
                           inputName="operation_type"
                           ref="operation_type"
                           inputType="radio"
                           inputLabelClass="text-font-primary fs-1 mb-3"
                           :inputValue="this.item.operation_type"
                           :inputOptions="this.getOperationsTypes()"
                           inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
                           inputWrapClass="flex-column mb-3"
                           inputRequired="true"
                           inputLazyLoad="true"
                           @searchInput="(term) => this.search_query = term"
                           @endOfList="(length) => this.clients_offset = length"
                           @setValue="(value) => this.setAndValidate('operation_type', value)" />
            <div v-show="!this.inArray(this.$props.returnPath, ['/add-client', '/edit-client'])" class="row">
              <div class="col-10 pe-0">
                <SelectBox inputId="object_owner"
                           inputLabel="Собственник:"
                           inputName="object_owner"
                           ref="object_owner"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-4"
                           :inputOptions="this.clients_list"
                           :inputValue="this.owners"
                           inputSearch="true"
                           inputMultiple="true"
                           inputLazyLoad="true"
                           :inputRequired="this.is_owner_required"
                           @searchInput="(term) => this.search_query = term"
                           @endOfList="(length) => this.clients_offset = length"
                           @setValue="(value) => this.setAndValidate('owners', value)" />
              </div>
              <div class="col-2">
                <a href="javascript:{}"
                   class="d-flex align-items-center justify-content-center bg-primary rounded-circle mx-auto"
                   style="width: 40px; height: 40px; margin-top: 40px;"
                   @click="this.toRoute((!this.isEmpty(this.item.id)) ? '/add-client?return=/edit-object&object_id='+this.item.id : '/add-client?return=/add-object')">
                  <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">
                </a>
              </div>
            </div>
            <ToggleButtons inputId="object_type"
                           inputLabel="Тип объекта:"
                           inputName="object_type"
                           ref="object_type"
                           inputType="radio"
                           inputLabelClass="text-font-primary fs-1 mb-3"
                           :inputValue="this.item.object_type"
                           :inputOptions="this.getObjectTypes()"
                           inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
                           inputWrapClass="flex-column mb-3"
                           inputRequired="true"
                           @setValue="(value) => this.setAndValidate('object_type', value)" />
            <ToggleButtons v-if="this.item.operation_type == 1"
                           inputId="lease_type"
                           inputLabel="Срок аренды"
                           inputName="lease_type"
                           ref="lease_type"
                           inputType="radio"
                           inputLabelClass="text-font-primary fs-1 mb-3"
                           :inputValue="this.item.lease_type"
                           :inputOptions="this.getLeaseTypes()"
                           inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
                           inputWrapClass="flex-column mb-3"
                           inputRequired="true"
                           @setValue="(value) => this.setAndValidate('lease_type', value)" />
            <ToggleButtons v-if="this.item.object_type == 3"
                           inputId="house_category_id"
                           inputLabel="Тип дома:"
                           inputName="house_category_id"
                           ref="house_category_id"
                           inputType="radio"
                           inputLabelClass="text-font-primary fs-1 mb-3"
                           :inputValue="this.item.house_category_id"
                           :inputOptions="this.getHouseCategories()"
                           inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
                           inputWrapClass="flex-column mb-3"
                           inputRequired="true"
                           @setValue="(value) => this.setAndValidate('house_category_id', value)"  />
            <ToggleButtons v-if="this.item.object_type == 4"
                           inputId="commercial_type"
                           inputLabel="Тип коммерции:"
                           inputName="commercial_type"
                           ref="commercial_type"
                           inputType="radio"
                           inputLabelClass="text-font-primary fs-1 mb-3"
                           :inputValue="this.item.commercial_type"
                           :inputOptions="this.getCommercialTypes()"
                           inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
                           inputWrapClass="flex-column mb-3"
                           inputRequired="true"
                           @setValue="(value) => this.setAndValidate('commercial_type', value)"  />
          </div>
          <div class="col" style="margin-bottom: 30px;">
            <div class="form-label text-font-primary fs-1 mb-3">Объект</div>
            <div class="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2">
              <label class="form-label col text-dark-gray">ID:</label>
              <div class="d-inline-flex align-items-stretch input-group col flex-fill w-auto">
                <span v-if="this.object_id">{{ this.item.id }}</span>
                <span v-else>Новый объект</span>
              </div>
            </div>
            <SelectBox 
                       inputId="status_object"
                       inputLabel="Статус:"
                       inputName="status_object"
                       ref="status_object"
                       :inputOptions="this.status_list"
                       :inputValue="this.item.status_object_id"
                       inputClass="rounded-1 p-2"
                       inputMultiple="false"
                       inputSearch="true"
                       inputRequired="true"
                       inputWrapClass="mb-20"
                       @setValue="(value) => this.setAndValidate('status_object_id', value)" />

            <div  v-if="this.object_id" class="d-flex flex-shrink-0 ms-auto">
              <a href="javascript:{}" class="btn d-flex align-items-center p-0" 
                @click="this.openTask" 
                >
                <span class="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary rounded-circle me-1" style="width: 1.875rem; height: 1.875rem;">
                  <img :src="require('@/assets/icons/phone-icon.svg')" class="d-block" alt="Дата прозвона" width="12" height="12">
                </span>
                <span class="text-dark ps-2">Дата прозвона: {{ this.item.date_call }}</span>
              </a>
            </div>           

          </div>  
          <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Локация</div>
            <InputSelect inputId="address_country_id"
                         inputLabel="Страна:"
                         inputName="address_country_id"
                         ref="address.country_id"
                         :inputValue="this.country_id"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputRequired="true"
                         inputUsePlaceholder="true"
                         :inputOptions="this.countries_list"
                         @setValue="(value) => this.setAndValidate('address.country_id', value)" />
            <InputSelect v-if="this.country_id == 171" inputId="address_region_id"
                         inputLabel="Название субъекта РФ (регион):"
                         inputName="address_region_id"
                         ref="address.region_id"
                         :inputValue="this.region_id"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputRequired="true"
                         inputUsePlaceholder="true"
                         :inputOptions="this.regions_list"
                         @setValue="(value) => this.setAndValidate('address.region_id', value)" />
            <InputText v-if="this.item.operation_type != 0" inputId="cadastral_flat"
                       inputClass="rounded-1 mb-10"
                       inputWrapClass="flex-column mb-2"
                       inputHelpClass="form-text mt-0"
                       inputName="cadastral_flat"
                       inputLabel="Кадастровый номер:"
                       ref="cadastral_flat"
                       :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для размещения на ДомКлик.'"
                       inputPlaceholder="XX:XX:XXXXXXX:XXX"
                       :inputValue="(this.item.cadastral) ? this.item.cadastral.flat : ''"
                       @setValue="(value) => this.setAndValidate('cadastral.flat', value)" />
            <InputText v-if="this.item.operation_type == 2 && this.inArray(this.item.object_type, [3])" inputId="cadastral_landscape"
                       inputClass="rounded-1 mb-10"
                       inputWrapClass="flex-column mb-2"
                       inputHelpClass="form-text mt-0"
                       inputName="cadastral_landscape"
                       inputLabel="Кадастровый номер участка:"
                       ref="cadastral_landscape"
                       :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для размещения на ДомКлик.'"
                       inputPlaceholder="XX:XX:XXXXXXX:XXX"
                       :inputValue="(this.item.cadastral) ? this.item.cadastral.landscape : ''"
                       @setValue="(value) => this.setAndValidate('cadastral.landscape', value)"  />
            <InputSelect inputId="property_type_id"
                         inputLabel="Юридический статус:"
                         inputName="property_type_id"
                         ref="property_type_id"
                         :inputValue="(this.item.property_type_id) ? this.item.property_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.property_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для размещения на ЦИАН и Авито.'"
                         @setValue="(value) => this.setAndValidate('property_type_id', value)" />           
            <InputText inputId="address_full"
                       inputLabel="Адрес:"
                       inputName="address_full"
                       ref="address.full"
                       inputPlaceholder="г.Город, Район р-н, ул.Улица, д.1, кв.1"
                       inputClass="rounded-1"
                       :inputValue="(this.item.address) ? this.item.address.full : ''"
                       inputWrapClass="flex-column mb-3"
                       inputRequired="true"
                       :inputVariants="this.address_variants"
                       @inputTyped="(value) => this.addressTyped(value)"
                       @onItemSelected="(value) => this.checkAddressInYa(value)"
                       @setValue="(value) => this.setAndValidate('address.full', value)" />
            <div id="editAdressForm" class="collapse">
              <InputText inputId="address_district"
                         inputLabel="Район региона:"
                         inputName="address_district"
                         ref="address.district"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="(this.item.address) ? this.item.address.district : ''"
                         @setValue="(value) => this.setAndValidate('address.district', value)" />
              <InputText inputId="address_city"
                         inputLabel="Населенный пункт:"
                         inputName="address_city"
                         ref="address.city"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="(this.item.address) ? this.item.address.city : ''"
                         @setValue="(value) => this.setAndValidate('address.city', value)" />
              <InputText inputId="address_city_district"
                         inputLabel="Район города:"
                         inputName="address_city_district"
                         ref="address.city_district"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="(this.item.address) ? this.item.address.city_district : ''"
                         @setValue="(value) => this.setAndValidate('address.city_district', value)" />
              <InputText inputId="address_street"
                         inputLabel="Улица:"
                         inputName="address_street"
                         ref="address.street"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="(this.item.address) ? this.item.address.street : ''"
                         @setValue="(value) => this.setAndValidate('address.street', value)" />
              <InputText inputId="address_house"
                         inputLabel="№ дома:"
                         inputName="address_house"
                         ref="address.house"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="(this.item.address) ? this.item.address.house : ''"
                         @setValue="(value) => this.setAndValidate('address.house', value)" />
              <InputText inputId="address_flat"
                         inputLabel="№ квартиры:"
                         inputName="address_flat"
                         ref="address.flat"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="(this.item.address) ? this.item.address.flat : ''"
                         @setValue="(value) => this.setAndValidate('address.flat', value)" />
              <InputText inputId="address_building"
                         inputLabel="Корпус:"
                         inputName="address_building"
                         ref="address.building"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="(this.item.address) ? this.item.address.building : ''"
                         @setValue="(value) => this.setAndValidate('address.building', value)" />
              <InputText inputId="address_litera"
                         inputLabel="Литера:"
                         inputName="address_litera"
                         ref="address.litera"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="(this.item.address) ? this.item.address.litera : ''"
                         @setValue="(value) => this.setAndValidate('address.litera', value)" />
              <InputText inputId="geo_lat"
                         inputLabel="Широта:"
                         inputName="geo_lat"
                         ref="geo.lat"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="this.geo_lat"
                         @setValue="(value) => this.setAndValidate('geo.lat', value)" />
              <InputText inputId="geo_lng"
                         inputLabel="Долгота:"
                         inputName="geo_lng"
                         ref="geo.lng"
                         inputClass="rounded-1 mb-10"
                         inputWrapClass="flex-column mb-2"
                         :inputValue="this.geo_lng"
                         @setValue="(value) => this.setAndValidate('geo.lng', value)" />
              <input type="hidden" name="address_kladr_id" ref="address_kladr_id" :value="(this.item.address) ? this.item.address.kladr_id : ''">
            </div>
            <ul class="list-unstyled mb-3">
              <li class="py-1">
                <a href="javascript:{}"
                   class="link fs-4"
                   data-bs-toggle="collapse"
                   data-bs-target="#editAdressForm"
                   aria-expanded="false"
                   aria-controls="editAdressForm"
                   @click="isEditAdressFormActive = !isEditAdressFormActive"
                   v-html="(isEditAdressFormActive) ? 'Скрыть форму' : this.icon(require('@/assets/icons/pen-icon.svg'), 'd-inline me-2', 'Edit', [16, 16]) + 'Редактировать адрес'" />
              </li>
              <li class="py-1">
                <a href="javascript:{}"
                   class="link fs-4"
                   data-bs-toggle="modal"
                   data-bs-target="#geoLocationModal"
                   :data-bs-lat="this.geo_lat"
                   :data-bs-lng="this.geo_lng"
                   v-html="this.icon(require('@/assets/icons/pen-icon.svg'), 'd-inline me-2', 'Edit', [16, 16]) + 'Уточнить координаты на карте'" />
              </li>
            </ul>
            <div class="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2">
              <label class="form-label col text-dark-gray">Метро:</label>
            </div>
                <template v-if="this.country_id == 171 && !this.isEmpty(this.item.metro.stations)">
                  <div v-for="(metro, index_metro) of this.item.metro.stations" :key="metro.id_metro">
                    <div class="row">
                      <div class="col-10 pe-0">
                        <SelectBox 
                            :inputId="'metro'+metro.id_metro"
                            inputLabel=""
                            :inputName="'metro'+metro.id_metro"
                            :ref="'metro'+metro.id_metro"
                            :inputOptions="this.metro_list"
                            :inputValue="metro.id_metro"
                            inputClass="rounded-1 p-2"
                            inputWrapClass="flex-column mb-4"
                            inputMultiple="false"
                            inputSearch="true"                            
                            @setValue="(value) => this.item.metro.stations[index_metro].id_metro = value" />
                          </div>
                          <div class="col-2">
                            <a href="javascript:{}"
                              class="d-flex align-items-center justify-content-center  rounded-circle mx-auto p-2 me-2"
                              style="height: 40px;"
                              @click="deleteMetro(index_metro)">
                              <img :src="require('@/assets/icons/close-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">
                            </a>
                          </div>
                        </div>
                      <div class="row">
                    
                      <div class="col-8">
                        <InputNumber :inputId="'metro_time'+metro.id_metro"
                                    inputLabel="До метро, мин.:"
                                    :inputName="'metro_time'+metro.id_metro"
                                    :ref="'metro_time'+metro.id_metro"
                                    :inputValue="(metro.subway_time) ? metro.subway_time : ''"
                                    inputClass="rounded-1 mb-10"
                                    inputWrapClass="flex-column mb-3"
                                    @setValue="(value) => this.item.metro.stations[index_metro].subway_time = value" />
                      </div>
                      <div class="col-4">
                        <InputSelect :inputId="'metro_type'+metro.id_metro"
                                    inputLabel="&nbsp;"
                                    :inputName="'metro_type'+metro.id_metro"
                                    :ref="'metro_type'+metro.id_metro"
                                    :inputOptions="this.to_metro_list"
                                    :inputValue="(metro.subway_distance) ? metro.subway_distance : ''"
                                    inputClass="rounded-1 mb-10"
                                    inputUsePlaceholder="true"
                                    inputWrapClass="flex-column mb-3"
                                    @setValue="(value) => this.item.metro.stations[index_metro].subway_distance = value" />

                      </div>
                    </div>
                  </div>
                </template>
                <div class="d-flex flex-shrink-0 ms-auto">
                  <a href="javascript:{}" class="btn d-flex align-items-center p-0" @click="add_station_metro">
                    <span class="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary rounded-circle me-1" style="width: 1.875rem; height: 1.875rem;">
                      <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Дата прозвона" width="12" height="12"></span>
                      <span class="text-dark ps-2">Добавить станцию метро</span>
                    </a>
                  </div>
              
                  <SelectBox inputId="area_analytic_id"
                            inputLabel="Район для аналитики:"
                            inputName="area_analytic_id"
                            ref="area_analytic_id"
                            :inputOptions="this.area_analytics_list"
                            :inputValue="this.item.area_analytic_id"
                            inputClass="rounded-1 p-2"
                            inputWrapClass="flex-column mb-4"
                            inputMultiple="false"
                            inputSearch="true"
                            inputRequired="true"                            
                            @setValue="(value) => this.setAndValidate('area_analytic_id', value)" />

                  <TextArea
                    inputId="directions"
                    inputLabel="Схема проезда к объекту:"
                    inputName="directions"
                    ref="directions"
                    :inputValue="this.item.directions"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-3"
                    @setValue="(value) => this.setAndValidate('directions', value)" />        
            
          </div>

          <!--Финансы-->
          <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Финансы</div>
            <div class="row">
              <div class="col-8">
                <InputText inputId="prices_amount"
                           inputName="prices_amount"
                           ref="prices.amount"
                           inputLabel="Цена:"
                           inputLabelClass="text-font-secondary mb-3"
                           inputClass="rounded-3 p-2"
                           inputWrapClass="mb-20"
                           :inputValue="(!this.isEmpty(this.item.prices)) ? this.item.prices.amount : 0"
                           :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                           inputMode="numeric"
                           inputStep="10000"
                           inputMin="0"
                           inputMax="2147000000"
                           inputIsPrice="true"
                           inputRequired="true"
                           @setValue="(value) => this.setAndValidate('prices.amount', value)" />
              </div>
              <div class="col-4">
                <InputSelect inputId="prices_currency"
                             inputLabel="Валюта:"
                             inputLabelClass="text-font-secondary mb-3"
                             inputName="prices_currency"
                             ref="prices.currency"
                             :inputOptions="this.currency_list"
                             :inputValue="(this.item.prices) ? this.item.prices.currency : 0"
                             inputClass="rounded-1 mb-10"
                             :inputUsePlaceholder="false"
                             inputWrapClass="flex-column mb-3"
                             @setValue="(value) => this.setAndValidate('prices.currency', value)" />
              </div>
            </div>
            <InputText v-if="this.item.prices.currency != 0" inputId="prices_price_avito"
                       inputName="prices_price_avito"
                       ref="prices.price_avito"
                       inputLabel="Цена в рублях для Avito:"
                       inputLabelClass="text-font-secondary mb-3"
                       inputClass="rounded-3 p-2"
                       inputWrapClass="mb-20"
                       :inputValue="(!this.isEmpty(this.item.prices)) ? this.item.prices.price_avito : 0"
                       :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                       inputMode="numeric"
                       inputStep="10000"
                       inputMin="0"
                       inputMax="2147000000"
                       inputIsPrice="true"
                       inputRequired="true"
                       @setValue="(value) => this.setAndValidate('prices.price_avito', value)" />

              <InputText inputId="stoim_kvm"
                       inputName="stoim_kvm"
                       ref="stoim_kvm"
                       inputLabel="Цена за кв.м.:"
                       inputLabelClass="text-font-secondary mb-3"
                       inputClass="rounded-3 p-2"
                       inputWrapClass="mb-20"
                       :inputValue="(!this.isEmpty(this.item.stoim_kvm)) ? this.item.stoim_kvm : ''"
                       :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                       inputMode="numeric"
                       inputStep="10000"
                       inputMin="0"
                       inputMax="2147000000"
                       inputIsPrice="true"
                       @setValue="(value) => this.setAndValidate('stoim_kvm', value)" />

            <InputCheckbox v-if="this.item.operation_type == 1 && !this.inArray(this.item.commercial_type, [4, 7])" inputId="prices_is_commission"
                           inputLabel="КУ отдельно"
                           inputName="prices_is_commission"
                           ref="prices.is_commission"
                           inputClass="mb-4"
                           inputWrapClass="flex-column mb-2"
                           :inputValue="(!this.isEmpty(this.item.prices.is_commission)) ? this.item.prices.is_commission : false"
                           @setValue="(value) => this.setAndValidate('prices.is_commission', value)" />
            <InputCheckbox v-else inputId="prices_is_ipoteka"
                           inputLabel="Возможна ипотека"
                           inputName="prices_is_ipoteka"
                           ref="prices.is_ipoteka"
                           inputClass="mb-4"
                           inputWrapClass="flex-column mb-2"
                           :inputValue="(!this.isEmpty(this.item.prices.is_ipoteka)) ? this.item.prices.is_ipoteka : false"
                           @setValue="(value) => this.setAndValidate('prices.is_ipoteka', value)" />
            <Switcher v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [1, 3, 5, 6, 8])" inputId="is_assignment_rent"
                      inputName="is_assignment_rent"
                      ref="is_assignment_rent"
                      inputLabel="Переуступка права аренды"
                      :inputValue="(!this.isEmpty(this.item.is_assignment_rent)) ? this.item.is_assignment_rent : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_assignment_rent', value)" />
            

            <Switcher v-if="this.item.operation_type == 2 && this.item.object_type == 1 && this.item.deal_type == 1" inputId="is_auction_sale"
                      inputName="is_auction_sale"
                      ref="is_auction_sale"
                      inputLabel="Аукционная продажа"
                      :inputValue="(!this.isEmpty(this.item.is_auction_sale)) ? this.item.is_auction_sale : false"
                      inputClass="px-0"
                      :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на Авито.'"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_auction_sale', value)" />
            

            <InputText v-if="this.item.operation_type == 1 && ((this.item.prices) ? this.item.prices.is_commission : false)" inputId="prices_amount_commission"
                       inputName="prices_amount_commission"
                       ref="prices.amount_commission"
                       inputLabel="Стоимость КУ:"
                       inputLabelClass="text-font-secondary mb-3"
                       inputClass="rounded-3 p-2"
                       inputWrapClass="mb-20"
                       :inputValue="(this.item.prices) ? this.item.prices.amount_commission : 0"
                       :inputRequired="(this.item.prices) ? this.item.prices.is_commission : false"
                       :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                       inputMode="numeric"
                       inputAppend=" руб."
                       inputMin="0"
                       inputMax="2147000000"
                       inputStep="1000"
                       inputIsPrice="true"
                       @setValue="(value) => this.setAndValidate('prices.amount_commission', value)" />
  
            <Switcher inputId="is_counters_price"
                      inputName="is_counters_price"
                      ref="is_counters_price"
                      inputLabel="Счётчики включены в цену"
                      :inputValue="(!this.isEmpty(this.item.is_counters_price)) ? this.item.is_counters_price : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_counters_price', value)" /> 

            <Switcher inputId="is_bargain"
                      inputName="iis_bargain"
                      ref="is_bargain"
                      inputLabel="Торг возможен"
                      :inputValue="(!this.isEmpty(this.item.is_bargain)) ? this.item.is_bargain : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_bargain', value)" />     
            <InputText v-if="this.item.is_bargain" inputId="price_bargain"
                       inputName="price_bargain"
                       ref="price_bargain"
                       inputLabel="Цена после торга:"
                       inputLabelClass="text-font-secondary mb-3"
                       inputClass="rounded-3 p-2"
                       inputWrapClass="mb-20"
                       :inputValue="(this.item.price_bargain) ? this.item.price_bargain : 0" 
                       :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                       inputMode="numeric"
                       inputAppend=" руб."
                       inputMin="0"
                       inputMax="2147000000"
                       inputStep="1000"
                       inputIsPrice="true"
                       @setValue="(value) => this.setAndValidate('price_bargain', value)" />   
              <div class="row">
                <div class="col-8">          
                  <InputNumber inputId="price_prepayment"
                         inputClass="rounded-1 mb-10"
                         inputLabel="Предоплата:"
                         inputName="price_prepayment"
                         ref="price_prepayment"
                         inputLabelClass="col text-dark-gray"
                         inputWrapClass="flex-column mb-2"
                         inputHelpClass="form-text mt-0"
                         :inputValue="this.item.price_prepayment"
                         :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                        inputMode="numeric"
                        inputMin="0"
                        inputMax="2147000000"
                        inputStep="1000"
                        inputIsPrice="true"
                         @setValue="(value) => this.setAndValidate('price_prepayment', value)" />
                  </div>
                  <div class="col-4">
                    <InputSelect inputId="price_prepayment_month"
                         inputLabel="за"
                         inputName="price_prepayment_month"
                         ref="price_prepayment_month"
                         :inputValue="(!this.isEmpty(this.item.price_prepayment_month)) ? this.item.price_prepayment_month : null"
                         :inputOptions="this.month_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="false"
                         @setValue="(value) => this.setAndValidate('price_prepayment_month', value)" />
                  </div>
                </div>
                <InputSelect inputId="lease_deposit_id"
                         inputLabel="Депозит:"
                         inputName="lease_deposit_id"
                         ref="lease_deposit_id"
                         :inputValue="(this.item.lease_deposit_id) ? this.item.lease_deposit_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.lease_deposit_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + '(Avito и Domofond)'"
                         @setValue="(value) => this.setAndValidate('lease_deposit_id', value)" />
            
                <InputNumber input-id="lease"
                         input-label="Депозит:"
                         inputLabelClass="col text-dark-gray"
                         inputName="lease"
                         ref="lease"
                         :inputValue="(!this.isEmpty(this.item.lease)) ? this.item.lease : null"
                         inputClass="rounded-1 mb-10"
                         inputAppend="руб."
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + '(ЦИАН)'"
                         @setValue="(value) => this.setAndValidate('lease', value)" /> 
                         
            <InputSelect inputId="min_rental_period"
                         inputLabel="Минимальный срок аренды:"
                         inputName="min_rental_period"
                         ref="min_rental_period"
                         :inputValue="(!this.isEmpty(this.item.min_rental_period)) ? this.item.min_rental_period : null"
                         :inputOptions="this.month_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="false"
                         @setValue="(value) => this.setAndValidate('min_rental_period', value)" />

            <InputSelect inputId="vat_type_id"
                         inputLabel="Налог:"
                         inputName="vat_type_id"
                         ref="vat_type_id"
                         :inputValue="(!this.isEmpty(this.item.vat_type_id)) ? this.item.vat_type_id : null"
                         :inputOptions="this.vat_types_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('vat_type_id', value)" />

            <InputNumber inputId="owner_interest"
                         inputLabel="% с собственника:"
                         inputName="owner_interest"
                         ref="owner_interest"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         inputLabelClass="col text-dark-gray"
                         :inputValue="(this.item.owner_interest) ? this.item.owner_interest : 0.0"
                         inputMin="0"
                         inputMax="100"
                         inputStep="0.1"
                         inputAppend=" %"
                         @setValue="(value) => this.setAndValidate('owner_interest', value)" />
              
            <Switcher inputId="owner_pays"
                      inputName="owner_pays"
                      ref="owner_pays"
                      inputLabel="Собственник платит"
                      :inputValue="(!this.isEmpty(this.item.owner_pays)) ? this.item.owner_pays : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('owner_pays', value)" />              

            <InputNumber inputId="pr_from_agent"
                         inputLabel="% с агента: "
                         inputName="pr_from_agent"
                         ref="pr_from_agent"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         inputLabelClass="col text-dark-gray"
                         :inputValue="(this.item.pr_from_agent) ? this.item.pr_from_agent : 0.0"
                         inputMin="0"
                         inputMax="100"
                         inputStep="0.1"
                         inputAppend=" %"
                         @setValue="(value) => this.setAndValidate('pr_from_agent', value)" />

            <InputNumber inputId="pr_from_lessee"
                         inputLabel="% с арендатора"
                         inputName="pr_from_lessee"
                         ref="pr_from_lessee"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         inputLabelClass="col text-dark-gray"
                         :inputValue="(this.item.pr_from_lessee) ? this.item.pr_from_lessee : 0.0"
                         inputMin="0"
                         inputMax="100"
                         inputStep="0.1"
                         inputAppend=" %"
                         @setValue="(value) => this.setAndValidate('pr_from_lessee', value)" />

            <InputNumber inputId="pr_bonus_agent"
                         inputLabel="% бонус агенту:"
                         inputName="pr_bonus_agent"
                         ref="pr_bonus_agent"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         inputLabelClass="col text-dark-gray"
                         :inputValue="(this.item.pr_bonus_agent) ? this.itempr_bonus_agent : 0.0"
                         inputMin="0"
                         inputMax="100"
                         inputStep="0.1"
                         inputAppend=" %"
                         @setValue="(value) => this.setAndValidate('pr_bonus_agent', value)" />

            <TextArea
                    inputId="dop_cost_owner"
                    inputLabel="Дополнительные затраты собственника:"
                    inputName="dop_cost_owner"
                    ref="dop_cost_owner"
                    :inputValue="this.item.dop_cost_owner"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-3"
                    @setValue="(value) => this.setAndValidate('dop_cost_owner', value)" />
            <TextArea
                    inputId="dop_cost_lessee"
                    inputLabel="Дополнительные затраты арендатора:"
                    inputName="dop_cost_lessee"
                    ref="dop_cost_lessee"
                    :inputValue="this.item.dop_cost_lessee"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-3"
                    @setValue="(value) => this.setAndValidate('dop_cost_lessee', value)" />
            
          </div>  

          <!-- Основная информация -->
          <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Основная информация</div>

            <InputNumber v-if="this.item.object_type != 7 && !(this.item.object_type == 4 && this.item.commercial_type == 9)" inputId="areas_full"
                           input-name="areas_full"
                           ref="areas.full"
                           inputClass="rounded-1 mb-10"
                           inputGroupClass="col flex-fill w-auto"
                           inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                           inputLabel="Общая площадь:"
                           inputLabelClass="col text-dark-gray"
                           :inputValue="(this.item.areas) ? this.item.areas.full : ''"
                           :inputRequired="this.inArray(this.item.operation_type, [1, 2]) && this.inArray(this.item.object_type, [3])"
                           inputMin="0"
                           inputMax="9999"
                           inputStep="0.5"
                           inputAppend=" кв.м."
                           @setValue="(value) => this.setAndValidate('areas.full', value)" />

            <InputNumber v-if="this.item.object_type != 4 && this.item.object_type != 7" inputId="areas_live"
                           input-name="areas_live"
                           ref="areas_live"
                           inputClass="rounded-1"
                           inputGroupClass="col flex-fill w-auto"
                           inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                           inputLabel="Жилая площадь:"
                           inputLabelClass="col text-dark-gray"
                           inputAppend=" кв.м."
                           :inputValue="(this.item.areas) ? this.item.areas.live : ''"
                           inputMin="0"
                           inputMax="999"
                           inputStep="0.5"
                           @setValue="(value) => this.setAndValidate('areas.live', value)" />

            <InputNumber v-if="this.item.object_type != 4 && this.item.object_type != 7" inputId="areas_kitchen"
                           input-name="areas_kitchen"
                           ref="areas_kitchen"
                           inputClass="rounded-1"
                           inputGroupClass="col flex-fill w-auto"
                           inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                           inputLabel="Площадь кухни:"
                           inputLabelClass="col text-dark-gray"
                           inputAppend=" кв.м."
                           :inputRequired="(this.item.is_studio)"
                           :inputValue="(this.item.areas) ? this.item.areas.kitchen : ''"
                           inputMin="0"
                           inputMax="999"
                           inputStep="0.5"
                           @setValue="(value) => this.setAndValidate('areas.kitchen', value)" />

            <div v-if="!this.inArray(this.item.object_type, [4, 7])" class="col">
                <InputNumber inputId="rooms_count"
                             inputName="rooms_count"
                             ref="rooms_count"
                             inputClass="rounded-1 mb-10"
                             inputGroupClass="col flex-fill w-auto"
                             inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                             inputLabelClass="col text-dark-gray"
                             inputLabel="Количество комнат:"
                             inputMin="0"
                             inputMax="999"
                             inputStep="1"
                             :inputRequired="(this.item.object_type == 1)"
                             :inputValue="!this.isEmpty(this.item.rooms_count) ? this.item.rooms_count : null"
                             @setValue="(value) => this.setAndValidate('rooms_count', value)" />

          <div class="row align-items-end" :class="{'row-cols-2': this.item.object_type == 1}">
              

              <div v-if="this.item.object_type == 1" class="col">
                <InputCheckbox inputId="is_studio"
                               inputName="is_studio"
                               ref="is_studio"
                               inputClass="mb-4"
                               inputWrapClass="flex-column mb-2"
                               inputLabel="Квартира-студия"
                               :inputDisabled="(this.item.rooms_count <= 0)"
                               :inputValue="!this.isEmpty(this.item.is_studio) ? this.item.is_studio : null"
                               @setValue="(value) => this.setAndValidate('is_studio', value)" />
              </div>
            </div>

                <InputNumber inputId=" bedroom"
                             inputName=" bedroom"
                             ref=" bedroom"
                             inputClass="rounded-1 mb-10"
                             inputGroupClass="col flex-fill w-auto"
                             inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                             inputLabelClass="col text-dark-gray"
                             inputLabel="Кол-во спален:"
                             inputMin="0"
                             inputMax="999"
                             inputStep="1"
                             :inputValue="!this.isEmpty(this.item.bedroom) ? this.item.bedroom : null"
                             @setValue="(value) => this.setAndValidate('bedroom', value)" />

                            
              </div>

              <InputSelect inputId="kitchen_type"
                         inputLabel="Кухня: "
                         inputName="kitchen_type"
                         ref="kitchen_type"
                         :inputValue="(!this.isEmpty(this.item.kitchen_type)) ? this.item.kitchen_type : null"
                         :inputOptions="this.kitchen_type"
                         
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         inputLabelClass="col text-dark-gray"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('kitchen_type', value)" />
              <InputNumber v-if="this.inArray(this.item.object_type, [1, 2])" input-id="separate_wcs"
                         input-label="Кол-во раздельных санузлов:"
                         inputLabelClass="col text-dark-gray"
                         inputName="separate_wcs"
                         ref="separate_wcs"
                         :inputValue="(!this.isEmpty(this.item.separate_wcs)) ? this.item.separate_wcs : null"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('separate_wcs', value)" />
            <InputNumber v-if="this.inArray(this.item.object_type, [1, 2])" input-id="combined_wcs"
                         input-label="Кол-во совместных санузлов:"
                         inputLabelClass="col text-dark-gray"
                         inputName="combined_wcs"
                         ref="combined_wcs"
                         :inputValue="(!this.isEmpty(this.item.combined_wcs)) ? this.item.combined_wcs : null"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('combined_wcs', value)" />  
                         
            <InputNumber v-if="this.inArray(this.item.object_type, [1, 2])" input-id="balcony"
                         input-label="Кол-во балконов:"
                         inputLabelClass="col text-dark-gray"
                         inputName="balcony"
                         ref="balcony"
                         :inputValue="(!this.isEmpty(this.item.balcony)) ? this.item.balcony : null"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('balcony', value)" />
            <InputNumber v-if="this.inArray(this.item.object_type, [1, 2])" input-id="loggias"
                         input-label="Кол-во лоджий:"
                         inputLabelClass="col text-dark-gray"
                         inputName="loggias"
                         ref="loggias"
                         :inputValue="(!this.isEmpty(this.item.loggias)) ? this.item.loggias : null"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('loggias', value)" />

            <Switcher  inputId="terassa"
                        inputName="terassa"
                        ref="terassa"
                        inputLabel="Терасса"
                        :inputValue="this.item.terassa"
                        inputClass="px-0"
                        inputWrapClass="flex-column mb-3"
                        @setValue="(value) => this.setAndValidate('terassa', value)" /> 
            
            <InputSelect inputId="renovation_site"
                         inputLabel="Тип ремонта для сайта:"
                         inputName="renovation_site"
                         ref="renovation_site"
                         :inputValue="this.item.renovation_site"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.renovation_site_list"
                         @setValue="(value) => this.setAndValidate('renovation_site', value)" />

           

            <InputSelect inputId="renovation_id"
                         inputLabel="Тип ремонта для ЦИАН:"
                         inputName="renovation_id"
                         ref="renovation_id"
                         :inputValue="(!this.isEmpty(this.item.renovation)) ? this.item.renovation : null"
                         :inputOptions="this.renovation_types_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('renovation_id', value)" />

            <InputSelect v-if="this.item.operation_type == 1 && this.inArray(this.item.object_type, [1, 2])" inputId="rent_house_type_id"
                         inputLabel="Тип дома:"
                         inputName="rent_house_type_id"
                         ref="rent_house_type_id"
                         :inputValue="(this.item.rent_house_type_id) ? this.item.rent_house_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         :inputOptions="rent_house_types_list"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('rent_house_type_id', value)" />

            <div v-if="this.item.object_type != 7 && !this.inArray(this.item.commercial_type, [7, 9])" class="row align-items-center" :class="{'row-cols-2': !(this.item.object_type == 5 || this.item.commercial_type == 6)}">
              <div v-if="!this.inArray(this.item.object_type, [3, 5]) && !this.inArray(this.item.commercial_type, [6])" class="col">
                <InputNumber inputId="floors_num"
                             inputName="floors_num"
                             ref="floors.num"
                             inputClass="rounded-1 mb-10"
                             inputGroupClass="col w-50"
                             inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                             inputLabelClass="col w-50 text-dark-gray"
                             inputLabel="Этаж:"
                             :inputRequired="false"
                             :inputValue="(this.item.floors) ? this.item.floors.num : null"
                             @setValue="(value) => this.setAndValidate('floors.num', value)" />
              </div>
              <div class="col" :class="{'col-12': (this.inArray(this.item.object_type, [3, 5]) || (this.item.object_type == 4 && this.item.commercial_type == 6))}">
                <InputNumber inputId="floors_max"
                             inputName="floors_max"
                             ref="floors.max"
                             inputClass="rounded-1 mb-10"
                             inputGroupClass="col w-50"
                             inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                             :inputRequired="false"
                             :inputLabel="(this.inArray(this.item.object_type, [3, 5]) || (this.item.object_type == 4 && this.item.commercial_type == 6)) ? 'Всего этажей:' : ' из '"
                             :inputLabelClass="(this.inArray(this.item.object_type, [3, 5]) || (this.item.object_type == 4 && this.item.commercial_type == 6)) ? 'form-label col text-dark-gray' : 'form-label col w-50 text-center text-dark-gray'"
                             :inputValue="(this.item.floors) ? this.item.floors.max : null"
                             @setValue="(value) => this.setAndValidate('floors.max', value)" />
              </div>
            </div>

            <div class="row align-items-center row-cols-2">
              <div  class="col">
                <InputNumber inputId="entrance"
                             inputName="entrance"
                             ref="entrance"
                             inputClass="rounded-1 mb-10"
                             inputGroupClass="col w-50"
                             inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                             inputLabelClass="col w-50 text-dark-gray"
                             inputLabel="Подъезд:"
                             :inputRequired="false"
                             :inputValue="(this.item.entrance) ? this.item.entrance : null"
                             @setValue="(value) => this.setAndValidate('entrance', value)" />
              </div>
              <div class="col">
                <InputNumber inputId="entrances"
                             inputName="entrances"
                             ref="entrances"
                             inputClass="rounded-1 mb-10"
                             inputGroupClass="col w-50"
                             inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                             :inputRequired="false"
                             inputLabel="из"
                             
                             :inputLabelClass="(this.inArray(this.item.object_type, [3, 5]) || (this.item.object_type == 4 && this.item.commercial_type == 6)) ? 'form-label col text-dark-gray' : 'form-label col w-50 text-center text-dark-gray'"
                            
                             :inputValue="(this.item.entrances) ? this.item.entrances : null"
                             @setValue="(value) => this.setAndValidate('entrances', value)" />
              </div>
            </div>

            <InputNumber v-if="/*this.item.operation_type == 2 && */this.inArray(this.item.object_type, [1, 2, 4]) && !this.inArray(this.item.commercial_type, [7, 9])" input-id="ceiling_height"
                         input-label="Высота потолков:"
                         inputLabelClass="col text-dark-gray"
                         inputName="ceiling_height"
                         ref="ceiling_height"
                         :inputValue="(!this.isEmpty(this.item.ceiling_height)) ? this.item.ceiling_height : null"
                         inputClass="rounded-1 mb-10"
                         inputStep="0.1"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('ceiling_height', value)" />

            <InputNumber input-id="level"
                         input-label="Кол-во уровней:"
                         inputLabelClass="col text-dark-gray"
                         inputName="level"
                         ref="level"
                         :inputValue="(!this.isEmpty(this.item.level)) ? this.item.level : null"
                         inputClass="rounded-1 mb-10"
                         inputStep="1"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('level', value)" />

            <InputSelect inputId="window_view_id"
                         inputLabel="Вид из окон:"
                         inputName="window_view_id"
                         ref="window_view_id"
                         :inputValue="(!this.isEmpty(this.item.window_view)) ? this.item.window_view : null"
                         :inputOptions="this.window_view_types_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('window_view_id', value)" />       
                         
            <InputSelect inputId="avito_room_type_id"
                         inputLabel="Тип комнат:"
                         inputName="avito_room_type_id"
                         ref="avito_room_type_id"
                         :inputValue="this.item.avito_room_type_id"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.getAvitoRoomTypes()"
                         @setValue="(value) => this.setAndValidate('avito_room_type_id', value)" />
              
            <Switcher inputId="penthouse"
                      inputName="penthouse"
                      ref="penthouse"
                      inputLabel="Пентхаус"
                      :inputValue="(!this.isEmpty(this.item.penthouse)) ? this.item.penthouse : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('penthouse', value)" />             

            <Switcher inputId="is_apartments"
                      inputName="is_apartments"
                      ref="is_apartments"
                      inputLabel="Апартаменты"
                      :inputValue="(!this.isEmpty(this.item.is_apartments)) ? this.item.is_apartments : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_apartments', value)" />

            <InputNumber v-if="this.inArray(this.item.object_type, [1, 2])" input-id="passenger_lifts"
                         input-label="Кол-во пассажирских лифтов:"
                         inputLabelClass="col text-dark-gray"
                         inputName="passenger_lifts"
                         ref="passenger_lifts"
                         :inputValue="(!this.isEmpty(this.item.passenger_lifts)) ? this.item.passenger_lifts : null"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('passenger_lifts', value)" />

            <InputNumber v-if="this.inArray(this.item.object_type, [1, 2])" input-id="cargo_lifts"
                         input-label="Кол-во грузовых лифтов:"
                         inputLabelClass="col text-dark-gray"
                         inputName="cargo_lifts"
                         ref="cargo_lifts"
                         :inputValue="(!this.isEmpty(this.item.cargo_lifts)) ? this.item.cargo_lifts : null"
                         inputClass="rounded-1 mb-10"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('cargo_lifts', value)" />

            <Switcher inputId="personal_lift"
                      inputName="personal_lift"
                      ref="personal_lift"
                      inputLabel="Персональный лифт"
                      :inputValue="(!this.isEmpty(this.item.personal_lift)) ? this.item.personal_lift : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('personal_lift', value)" />
           
            <div v-if="!this.isEmpty(this.item.object_room_types)" class="lw-control-group">
              <div class="lw-controls-with-desc lw-controls-with-desc--bordered lw-flex-column">
                <span>Тип комнаты</span>
                <div v-for="(room, index_room) of this.item.object_room_types" :key="index_room">
                  <div class="row">
                    <div class="col-7">
                      <InputSelect :inputId="'room_type'+index_room"
                                  :inputName="'room_type'+index_room"
                                  :ref="'room_type'+index_room"
                                  :inputOptions="this.room_types_list"
                                  :inputValue="(room.room_id) ? room.room_id : null"
                                  inputClass="rounded-1 mb-10"
                                  inputUsePlaceholder="true"
                                  inputWrapClass="flex-column mb-3"
                                  @setValue="(value) => this.item.object_room_types[index_room].room_id = value" />

                    </div>
                    <div class="col-4">
                      <InputNumber :inputId="'room_type'+index_room"
                                  :inputName="'room_type'+index_room"
                                  :ref="'room_type'+index_room"
                                  :inputValue="(room.area) ? room.area : null"
                                  inputClass="rounded-1 mb-10"
                                  inputWrapClass="flex-column mb-3"
                                  inputAppend=" кв.м."
                                  inputMin="0"
                                  inputMax="999"
                                  inputStep="0.1"
                                  @setValue="(value) => this.item.object_room_types[index_room].area = value" />
                    </div>
                    <div class="col-1">
                            <a href="javascript:{}"
                              class="d-flex align-items-center justify-content-center  rounded-circle mx-auto p-2 me-2"
                              style="height: 40px;"
                              @click="deleteRooms(index_room)">
                              <img :src="require('@/assets/icons/close-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">
                            </a>
                          </div>
                  </div>                
               </div>
              </div>
              </div>

              <div class="d-flex flex-shrink-0 ms-auto" style="margin-bottom: 30px;">
                  <a href="javascript:{}" class="btn d-flex align-items-center p-0" @click="add_room">
                    <span class="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary rounded-circle me-1" style="width: 1.875rem; height: 1.875rem;">
                      <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Дата прозвона" width="12" height="12"></span>
                      <span class="text-dark ps-2">Добавить комнату</span>
                    </a>
                  </div>
           </div> 

        <!-- Условия проживания -->
        <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Условия проживания</div> 
            <Switcher inputId="is_no_childs"
                      inputName="is_no_childs"
                      ref="is_no_childs"
                      inputLabel="Можно ли с детьми"
                      :inputValue="(!this.isEmpty(this.item.is_no_childs)) ? this.item.is_no_childs : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_no_childs', value)" />

            <Editor inputId="comment_no_child"
                    inputLabel="Комментарий по детям:"
                    inputName="comment_no_child"
                    ref="comment_no_child"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-2"
                    :inputValue="this.item.comment_no_child"
                    :editorConfig="{
								language: 'ru',
                    			toolbar: [ 'bold', 'italic', 'underline', '|', 'undo', 'redo', '|', 'cleanup', '|', 'numberedList', 'bulletedList' ]
							}"
                    @setValue="(value) => this.setAndValidate('comment_no_child', value)" />
            
            <Switcher inputId="is_no_pets"
                      inputName="is_no_pets"
                      ref="is_no_pets"
                      inputLabel="Можно ли с животными"
                      :inputValue="(!this.isEmpty(this.item.is_no_pets)) ? this.item.is_no_pets : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_no_pets', value)" />  
                      
            <Editor inputId="comment_no_pets"
                    inputLabel="Комментарий по животным:"
                    inputName="comment_no_pets"
                    ref="comment_no_pets"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-2"
                    :inputValue="this.item.comment_no_pets"
                    :editorConfig="{
								language: 'ru',
                    			toolbar: [ 'bold', 'italic', 'underline', '|', 'undo', 'redo', '|', 'cleanup', '|', 'numberedList', 'bulletedList' ]
							}"
                    @setValue="(value) => this.setAndValidate('comment_no_pets', value)" />
                    
            <InputSelect inputId="mebel_list_id"
                        inputName="mebel_list_id"
                        inputLabel="Мебель"
                        ref="mebel_list_id"
                        :inputOptions="this.mebel_list"
                        :inputValue="(this.item.mebel_list_id) ? this.item.mebel_list_id : null"
                        inputClass="rounded-1 mb-10"
                        inputUsePlaceholder="true"
                        inputWrapClass="flex-column mb-3"
                        @setValue="(value) => this.setAndValidate('mebel_list_id', value)" />

            <Editor inputId="comment_mebel"
                    inputLabel="Комментарий по мебели:"
                    inputName="comment_mebel"
                    ref="comment_mebel"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-2"
                    :inputValue="this.item.comment_mebel"
                    :editorConfig="{
								language: 'ru',
                    			toolbar: [ 'bold', 'italic', 'underline', '|', 'undo', 'redo', '|', 'cleanup', '|', 'numberedList', 'bulletedList' ]
							}"
                    @setValue="(value) => this.setAndValidate('comment_mebel', value)" />  
          <div class="row">
            <div class="col-8">        
              <InputSelect  inputId="parking_type_id"
                         inputLabel="Паркинг:"
                         inputName="parking_type_id"
                         ref="parking_type_id"
                         :inputValue="(!this.isEmpty(this.item.parking_type_id)) ? this.item.parking_type_id : null"
                         :inputOptions="this.parking_types1_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('parking_type_id', value)" />    
            </div>    
            <div class="col-4">
              <InputNumber inputId="no_parking_places"
                            inputName="no_parking_places"
                            inputLabel="Кол-во мест:"
                            ref="no_parking_places"
                                  :inputValue="(this.item.no_parking_places) ? this.item.no_parking_places : null"
                                  inputClass="rounded-1 mb-10"
                                  inputWrapClass="flex-column mb-3"
                                  inputMin="0"
                                  inputMax="999"
                                  inputStep="1"
                                  @setValue="(value) => this.setAndValidate('no_parking_places', value)" />
            </div> 
            </div>
            <Editor inputId="comment_no_parking"
                    inputLabel="Комментарий по паркингу:"
                    inputName="comment_no_parking"
                    ref="comment_no_parking"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-2"
                    :inputValue="this.item.comment_no_parking"
                    :editorConfig="{
								language: 'ru',
                    			toolbar: [ 'bold', 'italic', 'underline', '|', 'undo', 'redo', '|', 'cleanup', '|', 'numberedList', 'bulletedList' ]
							}"
                    @setValue="(value) => this.setAndValidate('comment_no_parking', value)" /> 

            <Switcher inputId="parking_space_included"
                      inputName="parking_space_included"
                      ref="parking_space_included"
                      inputLabel="Машиноместо включено в стоимость"
                      :inputValue="(!this.isEmpty(this.item.parking_space_included)) ? this.item.parking_space_included : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('parking_space_included', value)" /> 

            <InputSelect  inputId="migration_registration"
                         inputLabel="Миграционый учет:"
                         inputName="migration_registration"
                         ref="migration_registration"
                         :inputValue="(!this.isEmpty(this.item.migration_registration)) ? this.item.migration_registration : null"
                         :inputOptions="this.migration_registration_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('migration_registration', value)" />  

            <Switcher v-if="this.item.operation_type == 1 && !this.inArray(this.item.object_type, [4, 7])" inputId="is_smoking_allowed"
                      inputName="is_smoking_allowed"
                      ref="is_smoking_allowed"
                      inputLabel="Курение разрешено"
                      :inputValue="(!this.isEmpty(this.item.is_smoking_allowed)) ? this.item.is_smoking_allowed : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_smoking_allowed', value)" />

            <Switcher v-if="this.item.operation_type == 1 && !this.inArray(this.item.object_type, [4, 7])" inputId="is_parties_allowed"
                      inputName="is_parties_allowed"
                      ref="is_parties_allowed"
                      inputLabel="Вечеринки разрешены"
                      :inputValue="(!this.isEmpty(this.item.is_parties_allowed)) ? this.item.is_parties_allowed : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_parties_allowed', value)" />  
        </div>              
        <!-- Удобства -->
        <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Удобства</div>   
            <div class="row">
              <div class="col-6">         
                <span class="lw-control-group-title">Санузлы</span>  
                <Switcher inputId="bath"
                          inputName="bath"
                          ref="bath"
                          inputLabel="Ванна"
                          :inputValue="(!this.isEmpty(this.item.bath)) ? this.item.bath : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('bath', value)" />     
                
                <Switcher inputId="shower_cabin"
                          inputName="shower_cabin"
                          ref="shower_cabin"
                          inputLabel="Душевая кабина"
                          :inputValue="(!this.isEmpty(this.item.shower_cabin)) ? this.item.shower_cabin : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('shower_cabin', value)" />   

                <Switcher inputId="jacuzzi"
                          inputName="jacuzzi"
                          ref="jacuzzi"
                          inputLabel="Джакузи"
                          :inputValue="(!this.isEmpty(this.item.jacuzzi)) ? this.item.jacuzzi : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('jacuzzi', value)" />

                <Switcher inputId="sauna"
                          inputName="sauna"
                          ref="sauna"
                          inputLabel="Сауна"
                          :inputValue="(!this.isEmpty(this.item.sauna)) ? this.item.sauna : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('sauna', value)" />

                <span class="lw-control-group-title">Безопасность</span>
                <Switcher inputId="fire_exit"
                          inputName="fire_exit"
                          ref="fire_exit"
                          inputLabel="Пожарный выход"
                          :inputValue="(!this.isEmpty(this.item.fire_exit)) ? this.item.fire_exit : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('fire_exit', value)" />

                <Switcher inputId="concierge"
                          inputName="concierge"
                          ref="concierge"
                          inputLabel="Консьерж"
                          :inputValue="(!this.isEmpty(this.item.concierge)) ? this.item.concierge : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('concierge', value)" />

                <Switcher inputId="video_intercom"
                          inputName="video_intercom"
                          ref="video_intercom"
                          inputLabel="Видеодомофон"
                          :inputValue="(!this.isEmpty(this.item.video_intercom)) ? this.item.video_intercom : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('video_intercom', value)" />

                <Switcher inputId="signaling"
                          inputName="signaling"
                          ref="signaling"
                          inputLabel="Сигнализация"
                          :inputValue="(!this.isEmpty(this.item.signaling)) ? this.item.signaling : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('signaling', value)" />

                <Switcher inputId="security"
                          inputName="security"
                          ref="security"
                          inputLabel="Охрана"
                          :inputValue="(!this.isEmpty(this.item.security)) ? this.item.security : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('security', value)" />
                          
                <span class="lw-control-group-title">Инфраструктура</span>

                <Switcher inputId="pool"
                          inputName="pool"
                          ref="pool"
                          inputLabel="Бассейн"
                          :inputValue="(!this.isEmpty(this.item.pool)) ? this.item.pool : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('pool', value)" />

                <Switcher inputId="gym"
                          inputName="gym"
                          ref="gym"
                          inputLabel="Тренажерный зал"
                          :inputValue="(!this.isEmpty(this.item.gym)) ? this.item.gym : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('gym', value)" />

                <Switcher inputId="spa"
                          inputName="spa"
                          ref="spa"
                          inputLabel="Прочее"
                          :inputValue="(!this.isEmpty(this.item.spa)) ? this.item.spa : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('spa', value)" />

                <span class="lw-control-group-title">Прочее</span>

                <Switcher inputId="wardrobe"
                          inputName="wardrobe"
                          ref="wardrobe"
                          inputLabel="Гардеробная"
                          :inputValue="(!this.isEmpty(this.item.wardrobe)) ? this.item.wardrobe : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('wardrobe', value)" />

                <Switcher inputId="loft"
                          inputName="loft"
                          ref="loft"
                          inputLabel="Лофт"
                          :inputValue="(!this.isEmpty(this.item.loft)) ? this.item.loft : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('loft', value)" />

                <Switcher inputId="rubbish_chute"
                          inputName="rubbish_chute"
                          ref="rubbish_chute"
                          inputLabel="Мусоропровод"
                          :inputValue="(!this.isEmpty(this.item.rubbish_chute)) ? this.item.rubbish_chute : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('rubbish_chute', value)" />

              </div>
              <div class="col-6">         
                <span class="lw-control-group-title">Оборудование</span>  
                <Switcher inputId="internet"
                          inputName="internet"
                          ref="internet"
                          inputLabel="Интернет"
                          :inputValue="(!this.isEmpty(this.item.internet)) ? this.item.internet : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('internet', value)" />     
                
                <Switcher inputId="dishwasher"
                          inputName="dishwasher"
                          ref="dishwasher"
                          inputLabel="Посудомоечная машина"
                          :inputValue="(!this.isEmpty(this.item.dishwasher)) ? this.item.dishwasher : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('dishwasher', value)" /> 

                <Switcher inputId="washing_machine"
                          inputName="washing_machine"
                          ref="washing_machine"
                          inputLabel="Стиральная машина"
                          :inputValue="(!this.isEmpty(this.item.washing_machine)) ? this.item.washing_machine : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('washing_machine', value)" />

                <Switcher inputId="dryer"
                          inputName="dryer"
                          ref="dryer"
                          inputLabel="Сушильная машина"
                          :inputValue="(!this.isEmpty(this.item.dryer)) ? this.item.dryer : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('dryer', value)" />

                <Switcher inputId="boiler"
                          inputName="boiler"
                          ref="boiler"
                          inputLabel="Бойлер"
                          :inputValue="(!this.isEmpty(this.item.boiler)) ? this.item.boiler : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('boiler', value)" />

                <Switcher inputId="fireplace"
                          inputName="fireplace"
                          ref="fireplace"
                          inputLabel="Камин"
                          :inputValue="(!this.isEmpty(this.item.fireplace)) ? this.item.fireplace : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('fireplace', value)" />

                <Switcher inputId="cable_satellite_tv"
                          inputName="cable_satellite_tv"
                          ref="cable_satellite_tv"
                          inputLabel="Кабельное/спутниковое ТВ"
                          :inputValue="(!this.isEmpty(this.item.cable_satellite_tv)) ? this.item.cable_satellite_tv : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('cable_satellite_tv', value)" />
                <Switcher inputId="conditioner"
                          inputName="conditioner"
                          ref="conditioner"
                          inputLabel="Кондиционер"
                          :inputValue="(!this.isEmpty(this.item.conditioner)) ? this.item.conditioner : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('conditioner', value)" />

                <Switcher inputId="heated_floors"
                          inputName="heated_floors"
                          ref="heated_floors"
                          inputLabel="Подогрев полов"
                          :inputValue="(!this.isEmpty(this.item.heated_floors)) ? this.item.heated_floors : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('heated_floors', value)" />

                <span class="lw-control-group-title">Виды и расположение</span>

                <Switcher inputId="river_view"
                          inputName="river_view"
                          ref="river_view"
                          inputLabel="Вид на реку"
                          :inputValue="(!this.isEmpty(this.item.river_view)) ? this.item.river_view : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('river_view', value)" />

                <Switcher inputId="view_apartment"
                          inputName="view_apartment"
                          ref="view_apartment"
                          inputLabel="Видовая квартира"
                          :inputValue="(!this.isEmpty(this.item.view_apartment)) ? this.item.view_apartment : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('view_apartment', value)" />

                <Switcher inputId="near_water"
                          inputName="near_water"
                          ref="near_water"
                          inputLabel="Около воды"
                          :inputValue="(!this.isEmpty(this.item.near_water)) ? this.item.near_watert : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('near_water', value)" />

                <Switcher inputId="forest_area"
                          inputName="forest_area"
                          ref="forest_area"
                          inputLabel="Лесной участок"
                          :inputValue="(!this.isEmpty(this.item.forest_area)) ? this.item.forest_area : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('forest_area', value)" />

              </div> 
            </div>  
          </div>  
          
          <!-- Реклама -->
        <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Реклама</div>  
            <Editor inputId="description"
                    inputLabel="Описание для площадок:"
                    inputName="description"
                    ref="description"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-2"
                    :inputValue="this.item.description"
                    :editorConfig="{
								language: 'ru',
                    			toolbar: [ 'bold', 'italic', 'underline', '|', 'undo', 'redo', '|', 'cleanup', '|', 'numberedList', 'bulletedList' ]
							}"
                    :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Во избежании блокировки в описании не следует указывать телефон, сайт, e-mail или другие контакты.'"
                    @setValue="(value) => this.setAndValidate('description', value)" />
            <InputText inputId="nazv"
                       inputName="nazv"
                       ref="nazv"
                       inputClass="rounded-1 mb-10"
                       inputWrapClass="flex-column mb-2"
                       inputLabel="Заголовок объявления:"
                       :inputValue="!this.isEmpty(this.item.nazv) ? this.item.nazv : null"
                       @setValue="(value) => this.setAndValidate('nazv', value)" />
            
            <InputText v-if="this.item.operation_type > 0" inputId="video_url"
                       inputName="video_url"
                       ref="video_url"
                       inputClass="rounded-1 mb-10"
                       inputWrapClass="flex-column mb-2"
                       inputLabel="Ссылка на видео:"
                       inputPlaceholder="https://"
                       :inputValue="!this.isEmpty(this.item.video_url) ? this.item.video_url : null"
                       @setValue="(value) => this.setAndValidate('video_url', value)" />

            
              <label class="form-label col text-dark-gray">Смена id для площадок:</label>
              <div class="row">
                <div class="col-2">
                  <img class="lw-apartment-card__list-company-image" :src="require('@/assets/img/avito.svg')" alt="tick" width="80" height="32">
                </div>
                <div class="col-7">
                  <InputNumber inputId="replace_avito_id"
                            inputName="replace_avito_id"
                            ref="replace_avito_id"
                                  :inputValue="(this.item.replace_avito_id) ? this.item.replace_avito_id : null"
                                  inputClass="rounded-1 mb-10"
                                  inputWrapClass="flex-column mb-3"
                                  inputMin="0"
                                  inputMax="10000000"
                                  inputStep="1"
                                  @setValue="(value) => this.setAndValidate('replace_avito_id', value)" />
                </div>
                <div class="col-3">
                  <button class="lw-main-change-btn" type="button" @click="changeId(1);">
                    <span>Сменить id</span>
                  </button>
                </div>
              </div>  
              
              <div class="row">
                <div class="col-2">
                  <img class="lw-apartment-card__list-company-image" :src="require('@/assets/img/сian.svg')" alt="tick" width="80" height="32">
                </div>
                <div class="col-7">
                  <InputNumber inputId="replace_cian_id"
                            inputName="replace_cian_id"
                            ref="replace_cian_id"
                                  :inputValue="(this.item.replace_cian_id) ? this.item.replace_cian_id : null"
                                  inputClass="rounded-1 mb-10"
                                  inputWrapClass="flex-column mb-3"
                                  inputMin="0"
                                  inputMax="10000000"
                                  inputStep="1"
                                  @setValue="(value) => this.setAndValidate('replace_cian_id', value)" />
                </div>
                <div class="col-3">
                  <button class="lw-main-change-btn" type="button" @click="changeId(2);">
                    <span>Сменить id</span>
                  </button>
                </div>
              </div>  
              <TextArea
                    inputId="opis_ru"
                    inputLabel="Описание для сайта:"
                    inputName="opis_ru"
                    ref="opis_ru"
                    :inputValue="this.item.opis_ru"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-3"
                    @setValue="(value) => this.setAndValidate('opis_ru', value)" />

              <TextArea
                    inputId="opis_en"
                    inputLabel="Описание для сайта на англ.:"
                    inputName="opis_en"
                    ref="opis_en"
                    :inputValue="this.item.opis_en"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-3"
                    @setValue="(value) => this.setAndValidate('opis_en', value)" />
            </div>
        <!-- Прочее -->
          <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Прочее</div>
            
            <div class="row">
              <div class="col-2">
                Агент: 
              </div>
              <div class="col-8">
                <a class="lw-simple-btn lw-simple-btn--info-color" href="#"><span>{{ this.item.author.fio }}</span></a>
              </div>
            </div>

            <!-- Дополнительные поля -->
          <div class="row">
            
            <div class="col mb-3">
              <CustomFields v-if="!this.isEmpty(this.custom_fields_dop_list)"
                            ref="customFields"
                            :customFields="this.custom_fields_dop_list"
                            :customValues="(!this.isEmpty(this.item.fields)) ? this.item.fields : null"
                            :expanded = "true"
                            @inputInvalid="(error) => this.setError('fields', error)"
                            @setValue="(value) => this.setAndValidate('fields.'+value.name, value.value)" />
              
            </div>
          </div>

          <Switcher inputId="keys_offise"
                          inputName="keys_offise"
                          ref="keys_offise"
                          inputLabel="Ключи в офисе"
                          :inputValue="(!this.isEmpty(this.item.keys_offise)) ? this.item.keys_offise : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('keys_offise', value)" />

          <Switcher inputId="is_land_burden"
                      inputName="is_land_burden"
                      ref="is_land_burden"
                      inputLabel="Есть обременения"
                      :inputValue="(!this.isEmpty(this.item.is_land_burden)) ? this.item.is_land_burden : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_land_burden', value)" />

            <SelectBox v-if="this.item.operation_type > 0" inputId="tags"
                       inputLabel="Теги объекта:"
                       inputName="tags"
                       ref="tags"
                       inputPlaceholder="Укажите теги"
                       :inputValue="!this.isEmpty(this.item.tags) ? this.item.tags : null"
                       inputClass="rounded-1 p-2"
                       inputWrapClass="flex-column mb-2"
                       :inputOptions="this.tags_list"
                       inputMultiple="true"
                       @setValue="(value) => this.setAndValidate('tags', value)" />
              
            <TextArea
                    inputId="internal_intermark_comments"
                    inputLabel="Комментарий менеджера Intermark Property:"
                    inputName="internal_intermark_comments"
                    ref="internal_intermark_comments"
                    :inputValue="this.item.internal_intermark_comments"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-3"
                    @setValue="(value) => this.setAndValidate('internal_intermark_comments', value)" />  
                    
            <TextArea
                    inputId="intermark_relocation_comments"
                    inputLabel="Внутренний комментарий Intermark Relocation:"
                    inputName="intermark_relocation_comments"
                    ref="intermark_relocation_comments"
                    :inputValue="this.item.intermark_relocation_comments"
                    inputClass="rounded-1 mb-10"
                    inputWrapClass="flex-column mb-3"
                    @setValue="(value) => this.setAndValidate('intermark_relocation_comments', value)" />
          </div>
            <!--<div v-if="this.item.operation_type == 2" class="row row-cols-2 align-items-end">
              <div class="col">
                <InputText inputClass="rounded-1 mb-10"
                           inputWrapClass="flex-column mb-2"
                           inputLabel="Размер доли:"
                           inputPlaceholder="Например: 1/3"
                           :inputValue="this.item.owner_part"
                           :inputDisabled="!(this.item.is_owner_part)"
                           @setValue="(value) => this.setAndValidate('owner_part', value)" />
              </div>
              <div class="col">
                <InputCheckbox inputClass="mb-4"
                               inputWrapClass="flex-column mb-2"
                               inputLabel="Доля"
                               :inputValue="!this.isEmpty(this.item.is_owner_part) ? this.item.is_owner_part : null"
                               @setValue="(value) => { (value) ? this.item.is_owner_part = 1 : this.item.is_owner_part = 0}" />

              </div>
            </div>-->

            <div v-if="!this.inArray(this.item.object_type, [6, 9])">

              <Switcher v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 8])" inputId="is_parts_enabled"
                        inputName="is_parts_enabled"
                        ref="is_parts_enabled"
                        inputLabel="Возможна продажа частями"
                        :inputValue="this.item.is_parts_enabled"
                        inputClass="px-0"
                        inputWrapClass="flex-column mb-3"
                        @setValue="(value) => this.setAndValidate('is_parts_enabled', value)" />
              <div v-if="(this.item.is_parts_enabled) && this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 8])" class="row mb-3">
                <div v-if="!this.isEmpty(this.item.parts_area)">
                  <InputNumber v-for="(area, index) in this.item.parts_area"
                               :key="index"
                               :inputId="'parts_area_'+index"
                               :inputName="'parts_area_'+index"
                               :ref="'parts_area.'+index"
                               :data-set-num="num = (index+1)"
                               :data-set-key="skey = (index-1)"
                               inputClass="rounded-1 mb-10"
                               inputGroupClass="col flex-fill w-auto"
                               inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                               :inputLabel="'Площадь #'+(index+1)+':'"
                               inputLabelClass="col text-dark-gray"
                               :inputValue="(!this.isEmpty(area)) ? area : null"
                               inputMin="0"
                               inputMax="9999"
                               inputStep="0.5"
                               inputRemove="true"
                               @removeValue="(value) => this.removeArea(skey, value)"
                               @setValue="(value) => this.setAndValidate('parts_area.'+index, value)" />
                </div>
                <div class="col">
                  <a href="javascript:{}"
                     class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
                     style="min-width: 150px;"
                     @click.stop.prevent="this.item.parts_area.push('0')">
                    <span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
                          style="width: 40px; height: 40px;">
                      <img :src="require('@/assets/icons/add-icon.svg')"
                           class="d-block"
                           alt="Добавить"
                           width="14"
                           height="14">
                    </span>
                    <span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить площадь</span>
                  </a>
                </div>
              </div>
              <InputNumber v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="areas_land"
                           input-name="areas_land"
                           ref="areas.land"
                           inputClass="rounded-1"
                           inputGroupClass="col flex-fill w-auto"
                           inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                           inputLabel="Площадь участка, сот.:"
                           inputLabelClass="col text-dark-gray"
                           :inputHelpText="this.icon(
										 require('@/assets/icons/info.svg'),
										 'd-inline me-2',
										 'Info', [16, 16]
									 ) + 'Необходимо для рекламы на Avito, Domofond, ЦИАН и ЕМЛС.'"
                           inputHelpClass="col w-100 mb-10"
                           :inputValue="(this.item.areas) ? this.item.areas.land : ''"
                           :inputRequired="this.inArray(this.item.operation_type, [1, 2]) && this.inArray(this.item.object_type, [3, 7])"
                           inputMin="0"
                           inputMax="999"
                           inputStep="0.5"
                           @setValue="(value) => this.setAndValidate('areas.land', value)" />
              <InputSelect v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [6, 7, 8, 9])" inputId="legal_address"
                           inputName="legal_address"
                           ref="legal_address"
                           inputLabel="Юридический адрес:"
                           :inputValue="this.item.legal_address ?? null"
                           inputClass="rounded-1"
                           inputWrapClass="flex-column mb-3"
                           inputUsePlaceholder="true"
                           :inputOptions="this.legal_address_list"
                           @setValue="(value) => this.setAndValidate('legal_address', value)" />
            </div>
            
            <!--<InputSelect v-if="!this.isEmpty(this.item.floors) ? (this.item.floors.num == 1) : false"
                         inputId="floors_first_type"
                         inputName="floors_first_type"
                         ref="floors.first_type"
                         inputLabel="Тип первого этажа:"
                         :inputValue="(this.item.floors) ? this.item.floors.first_type : null"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.getFirstFloorTypes()"
                         @setValue="(value) => this.setAndValidate('floors.first_type', value)" />
            <Switcher v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [1, 3, 5])" inputId="is_occupied"
                      inputName="is_occupied"
                      ref="is_occupied"
                      inputLabel="Помещение занято"
                      :inputValue="(!this.isEmpty(this.item.is_occupied)) ? this.item.is_occupied : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_occupied', value)" />
            <DateTimePicker v-if="this.item.object_type == 4 && this.item.is_occupied" inputId="occupied_date"
                            inputLabel="Дата освобождения:"
                            inputName="occupied_date"
                            ref="occupied_date"
                            inputLabelClass="text-font-secondary mb-3"
                            inputClass="rounded-3 p-2"
                            inputWrapClass="mb-20"
                            :inputValue="(!this.isEmpty(this.item.occupied_date)) ? this.item.occupied_date : false"
                            displayFormat="DD.MM.YYYY"
                            @setValue="(value) => this.setAndValidate('occupied_date', value)" />
            <InputSelect v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6])" inputId="layout_id"
                         inputLabel="Планировка:"
                         inputName="layout_id"
                         ref="layout_id"
                         :inputValue="(!this.isEmpty(this.item.layout_id)) ? this.item.layout_id : false"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.layouts_list"
                         @setValue="(value) => this.setAndValidate('layout_id', value)" />
            <InputSelect v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [6, 7, 8, 9])" inputId="number_wet_spots"
                         inputLabel="Количество мокрых точек:"
                         inputName="number_wet_spots"
                         ref="number_wet_spots"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.wet_spots_list"
                         :inputValue="(this.item.number_wet_spots) ? this.item.number_wet_spots : null"
                         @setValue="(value) => this.setAndValidate('number_wet_spots', value)" />
            <InputNumber v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [6, 7, 8, 9])" inputId="electricity_power"
                         inputLabel="Электрическая мощность:"
                         inputName="electricity_power"
                         ref="electricity_power"
                         inputStep="1"
                         inputMin="0"
                         inputMax="1000"
                         inputAppend="кВт."
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         :inputValue="(this.item.electricity_power) ? this.item.electricity_power : null"
                         @setValue="(value) => this.setAndValidate('electricity_power', value)" />
            <InputSelect v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 9])" inputId="condition_id"
                         inputLabel="Состояние:"
                         inputName="condition_id"
                         ref="condition_id"
                         inputClass="rounded-1 p-2"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputValue="(this.item.condition_id) ? this.item.condition_id : null"
                         :inputOptions="this.conditions_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на Avito и ЦИАН.'"
                         @setValue="(value) => this.setAndValidate('condition_id', value)" />
            <Switcher v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [1, 3, 5, 6, 8])" inputId="is_furniture"
                      inputLabel="Мебель"
                      inputName="is_furniture"
                      ref="is_furniture"
                      :inputValue="(!this.isEmpty(this.item.is_furniture)) ? this.item.is_furniture : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_furniture', value)" />
            <InputSelect v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [1, 4, 5])" inputId="access_id"
                         inputName="access_id"
                         ref="access_id"
                         inputLabel="Доступ:"
                         :inputValue="(this.item.access_id) ? this.item.access_id : null"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.access_list"
                         @setValue="(value) => this.setAndValidate('access_id', value)" />
            
            				<InputRange v-if="this.item.operation_type != 0" inputId="price_amount"
								inputLabel="Цена:"
								inputLabelClass="text-dark-gray mb-3"
								inputName="prices_amount"
								ref="prices.amount"
								:inputValue="(!this.isEmpty(this.item.prices)) ? this.item.prices.amount : 0"
								inputAppend=" руб."
								inputStep="10000"
								inputMin="0"
								inputMax="2147000000"
								inputIsPrice="true"
								inputRequired="true"
								inputClass="rounded-1 mb-10"
								:inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо указывать полную цену в рублях.'"
								inputWrapClass="flex-column mb-2"
								@setValue="(value) => this.setAndValidate('prices.amount', value)" />-->
            
            
            
            <!--<InputSelect v-if="this.inArray(this.item.object_type, [3, 5])" inputId="wall_type_id"
                         inputLabel="Материал стен:"
                         inputName="wall_type_id"
                         ref="wall_type_id"
                         :inputValue="(this.item.wall_type_id) ? this.item.wall_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.wall_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для размещения на Avito и Domofond.'"
                         @setValue="(value) => this.setAndValidate('wall_type_id', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5])" inputId="house_wcs_type_id"
                         inputLabel="Санузел:"
                         inputName="house_wcs_type_id"
                         ref="house_wcs_type_id"
                         :inputValue="(this.item.house_wcs_type_id) ? this.item.house_wcs_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.house_wcs_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для размещения на Avito и Domofond.'"
                         @setValue="(value) => this.setAndValidate('house_wcs_type_id', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="land_purpose_id"
                         inputLabel="Назначение земли:"
                         inputName="land_purpose_id"
                         ref="land_purpose_id"
                         :inputValue="(this.item.land_purpose_id) ? this.item.land_purpose_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.land_purposes_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         @setValue="(value) => this.setAndValidate('land_purpose_id', value)" />
            <InputNumber v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="land_distance"
                         inputLabel="Удаленность от города, райцентра:"
                         inputName="land_distance"
                         ref="land_distance"
                         inputClass="rounded-1 mb-10"
                         inputLabelClass="col text-dark-gray"
                         inputWrapClass="flex-column mb-2"
                         inputHelpClass="form-text mt-0"
                         :inputValue="(this.item.land_distance) ? this.item.land_distance : ''"
                         inputMin="0"
                         inputMax="200"
                         inputStep="1"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         inputAppend="км"
                         @setValue="(value) => this.setAndValidate('land_distance', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="shape_type_id"
                         inputLabel="Форма участка:"
                         inputName="shape_type_id"
                         ref="shape_type_id"
                         :inputValue="(this.item.shape_type_id) ? this.item.shape_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.shape_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         @setValue="(value) => this.setAndValidate('shape_type_id', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="plumbing_type_id"
                         inputLabel="Водо-снабжение:"
                         inputName="plumbing_type_id"
                         ref="plumbing_type_id"
                         :inputValue="(this.item.plumbing_type_id) ? this.item.plumbing_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.plumbing_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         @setValue="(value) => this.setAndValidate('plumbing_type_id', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="gas_type_id"
                         inputLabel="Газификация:"
                         inputName="gas_type_id"
                         ref="gas_type_id"
                         :inputValue="(this.item.gas_type_id) ? this.item.gas_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.gas_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         @setValue="(value) => this.setAndValidate('gas_type_id', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="electricity_type_id"
                         inputLabel="Электро-снабжение:"
                         inputName="electricity_type_id"
                         ref="electricity_type_id"
                         :inputValue="(this.item.electricity_type_id) ? this.item.electricity_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.electricity_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         @setValue="(value) => this.setAndValidate('electricity_type_id', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="sewerage_type_id"
                         inputLabel="Канализация:"
                         inputName="sewerage_type_id"
                         ref="sewerage_type_id"
                         :inputValue="(this.item.sewerage_type_id) ? this.item.sewerage_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.sewerage_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         @setValue="(value) => this.setAndValidate('sewerage_type_id', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="relief_type_id"
                         inputLabel="Рельеф:"
                         inputName="relief_type_id"
                         ref="relief_type_id"
                         :inputValue="(this.item.relief_type_id) ? this.item.relief_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.relief_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         @setValue="(value) => this.setAndValidate('relief_type_id', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [3, 5, 7])" inputId="land_usage_type_id"
                         inputLabel="Использование земли:"
                         inputName="land_usage_type_id"
                         ref="land_usage_type_id"
                         :inputValue="(this.item.land_usage_type_id) ? this.item.land_usage_type_id : ''"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputOptions="this.land_usage_types_list"
                         :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Необходимо для рекламы на сайтах-партнерах.'"
                         @setValue="(value) => this.setAndValidate('land_usage_type_id', value)" />
            
            
            
            
            
            
            
            
            
            <Switcher v-if="this.item.operation_type == 1 && !this.inArray(this.item.object_type, [4, 7])" inputId="is_documents_do"
                      inputName="is_documents_do"
                      ref="is_documents_do"
                      inputLabel="Отчётные документы"
                      :inputValue="(!this.isEmpty(this.item.is_documents_do)) ? this.item.is_documents_do : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_documents_do', value)" />
            
            
            
            <InputSelect v-if="/*this.item.operation_type == 2 && */this.inArray(this.item.object_type, [1, 2])" inputId="bathroom_type_id"
                         inputLabel="Тип ванны:"
                         inputName="bathroom_type_id"
                         ref="bathroom_type_id"
                         :inputValue="(!this.isEmpty(this.item.bathroom_type_id)) ? this.item.bathroom_type_id : null"
                         :inputOptions="this.bathroom_types_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputHelpText="this.icon(
									 require('@/assets/icons/info.svg'),
									 'd-inline me-2',
									 'Info', [16, 16]
								 ) + 'Необходимо для рекламы на Zipal и Avito.'"
                         @setValue="(value) => this.setAndValidate('bathroom_type_id', value)" />
            <InputSelect v-if="/*this.item.operation_type == 2 && */this.inArray(this.item.object_type, [1, 2])" inputId="hot_water_id"
                         inputLabel="Обеспечение горячей водой:"
                         inputName="hot_water_id"
                         ref="hot_water_id"
                         :inputValue="(!this.isEmpty(this.item.hot_water_id)) ? this.item.hot_water_id : null"
                         :inputOptions="this.hot_water_types_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputHelpText="this.icon(
									 require('@/assets/icons/info.svg'),
									 'd-inline me-2',
									 'Info', [16, 16]
								 ) + 'Необходимо для рекламы на Zipal и ЕМЛС.'"
                         @setValue="(value) => this.setAndValidate('hot_water_id', value)" />
            <Switcher v-if="/*this.item.operation_type == 2 && */this.inArray(this.item.object_type, [1, 2])" inputId="is_rubbish_chute"
                      inputName="is_rubbish_chute"
                      ref="is_rubbish_chute"
                      inputLabel="Мусоропровод"
                      :inputValue="(!this.isEmpty(this.item.is_rubbish_chute)) ? this.item.is_rubbish_chute : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_rubbish_chute', value)" />
            
            <InputSelect v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [2, 4])" inputId="parking_id"
                         inputLabel="Парковка:"
                         inputName="parking_id"
                         ref="parking_id"
                         :inputValue="(!this.isEmpty(this.item.parking_id)) ? this.item.parking_id : null"
                         :inputOptions="this.parking_types2_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('parking_id', value)" />
            <InputSelect v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [2, 4])" inputId="parking_sub_type_id"
                         inputLabel="Тип парковки:"
                         inputName="parking_sub_type_id"
                         ref="parking_sub_type_id"
                         :inputValue="(!this.isEmpty(this.item.parking_sub_type_id)) ? this.item.parking_sub_type_id : null"
                         :inputOptions="this.parking_types3_list"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('parking_sub_type_id', value)" />
            <InputNumber v-if="!((!this.isEmpty(this.item.is_parking_free)) ? this.item.is_parking_free : false) && this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 8])" input-id="parking_price"
                         input-label="Стоимость парковки:"
                         inputLabelClass="col text-dark-gray"
                         inputName="parking_price"
                         ref="parking_price"
                         :inputValue="(!this.isEmpty(this.item.parking_price)) ? this.item.parking_price : null"
                         inputClass="rounded-1 mb-10"
                         inputAppend="руб. в месяц"
                         :inputDisabled="this.item.is_parking_free"
                         inputGroupClass="col flex-fill w-auto"
                         inputWrapClass="row row-cols-auto row-cols-sm-2 align-items-center flex-row mb-2"
                         @setValue="(value) => this.setAndValidate('parking_price', value)" />
            <Switcher v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 8])" inputId="is_parking_free"
                      inputName="is_parking_free"
                      ref="is_parking_free"
                      inputLabel="Бесплатная парковка"
                      :inputValue="(!this.isEmpty(this.item.is_parking_free)) ? this.item.is_parking_free : false"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_parking_free', value)" />
            
            <InputText v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [2, 4, 5])" inputId="column_grid"
                       inputLabel="Сетка колон:"
                       inputName="column_grid"
                       ref="column_grid"
                       :inputValue="(!this.isEmpty(this.item.column_grid)) ? this.item.column_grid : null"
                       inputClass="rounded-1 p-2"
                       inputPlaceholder="Например: 9х3, 3х3"
                       inputWrapClass="flex-column mb-2"
                       @setValue="(value) => this.setAndValidate('column_grid', value)"/>
            
          </div> -->

          <!-- Ещё про коммерцию -->
           <div class="row">
            <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 9])"
                 class="row-fluid">
              <div class="fs-2 my-3">Участок</div>
              <div class="col">
                <InputNumber inputId="commerce_area_size"
                             inputName="commerce_area_size"
                             ref="commerce.area_size"
                             inputClass="rounded-1 mb-10"
                             :inputLabel="false"
                             inputStep="0.1"
                             inputMin="0"
                             inputMax="100000"
                             inputWrapClass="flex-column mb-2"
                             inputAppend="га"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.area_size : null"
                             @setValue="(value) => this.setAndValidate('commerce.area_size', value)" />
                <InputRadio inputId="commerce_area_is_owned"
                            inputName="commerce_area_is_owned"
                            ref="commerce.area_is_owned"
                            inputClass="rounded-1 mb-10"
                            inputLabel="- в собственности"
                            inputWrapClass="flex-column mb-2"
                            :inputValue="(!this.isEmpty(this.item.commerce)) ? (this.item.commerce.area_is_owned ? 0 : 1) : 0"
                            @setValue="(value) => this.setAndValidate('commerce.area_is_owned', value)" />
                <InputRadio inputId="commerce_area_is_owned2"
                            inputName="commerce_area_is_owned"
                            ref="commerce.area_is_owned"
                            inputClass="rounded-1 mb-10"
                            inputLabel="- в аренде"
                            inputWrapClass="flex-column mb-2"
                            :inputValue="(!this.isEmpty(this.item.commerce)) ? (this.item.commerce.area_is_owned ? 1 : 0) : 1"
                            @setValue="(value) => this.setAndValidate('commerce.area_is_owned', value)" />
              </div>
            </div>
            <div v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [2, 4, 5])"
                 class="col">
              <InputSelect inputId="floor_material_id"
                           inputLabel="Материал пола:"
                           inputName="floor_material_id"
                           ref="floor_material_id"
                           :inputValue="(!this.isEmpty(this.item.floor_material_id)) ? this.item.floor_material_id : null"
                           inputClass="rounded-1"
                           inputWrapClass="flex-column mb-3"
                           inputUsePlaceholder="true"
                           :inputOptions="this.floor_materials_list"
                           @setValue="(value) => this.setAndValidate('floor_material_id', value)" />
              <InputSelect inputId="commerce_portal_id"
                           inputLabel="Ворота:"
                           inputName="commerce_portal_id"
                           ref="commerce.portal_id"
                           :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.portal_id : null"
                           inputClass="rounded-1"
                           inputWrapClass="flex-column mb-3"
                           inputUsePlaceholder="true"
                           :inputOptions="this.commerce_portal_list"
                           @setValue="(value) => this.setAndValidate('commerce.portal_id', value)" />
            </div>
            <div v-if="this.item.object_type == 4" class="row-fluid">
              <div v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 8])"
                   class="col mb-3" >
                <SelectBox inputLabel="Назначение:"
                           inputId="commerce_purposes"
                           inputName="commerce_purposes"
                           ref="commerce.purposes"
                           :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.purposes : null"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-2"
                           :inputOptions="this.commerce_purpose_list"
                           inputSearch="true"
                           inputMultiple="true"
                           @setValue="(value) => this.setAndValidate('commerce.purposes', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [9])"
                   class="col mb-3">
                <InputSelect inputId="commerce_land_category_id"
                             inputLabel="Категория земли:"
                             inputName="commerce_land_category_id"
                             ref="commerce.land_category_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.land_category_id : null"
                             inputClass="rounded-1"
                             inputWrapClass="flex-column mb-3"
                             inputUsePlaceholder="true"
                             :inputOptions="this.commerce_land_categories_list"
                             @setValue="(value) => this.setAndValidate('commerce.land_category_id', value)" />
                <InputCheckbox inputId="commerce_can_change_land_category"
                               inputLabel="- можно изменить"
                               inputName="commerce_can_change_land_category"
                               :inputDisabled="(!this.isEmpty(this.item.commerce)) ? !(this.item.commerce.land_category_id) : false"
                               ref="commerce_can_change_land_category"
                               :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.can_change_land_category : false"
                               @setValue="(value) => this.setAndValidate('commerce.can_change_land_category', ((value) ? this.item.commerce.can_change_land_category = 1 : this.item.commerce.can_change_land_category = 0))" />
              </div>
              <div v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [9])"
                   class="col mb-3">
                <InputSelect inputId="commerce_permitted_use_id"
                             inputLabel="Вид разрешенного использования:"
                             inputName="commerce_permitted_use_id"
                             ref="commerce.permitted_use_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.permitted_use_id : null"
                             inputClass="rounded-1"
                             inputWrapClass="flex-column mb-3"
                             inputUsePlaceholder="true"
                             :inputOptions="this.commerce_permitted_use_list"
                             @setValue="(value) => this.setAndValidate('commerce.permitted_use_id', value)" />
                <InputCheckbox inputId="commerce_can_change_permitted_use"
                               inputLabel="- можно изменить"
                               inputName="commerce_can_change_permitted_use"
                               :inputDisabled="(!this.isEmpty(this.item.commerce)) ? !(this.item.commerce.permitted_use_id) : false"
                               :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.can_change_permitted_use : false"
                               ref="commerce.can_change_permitted_use"
                               @setValue="(value) => this.setAndValidate('commerce.can_change_permitted_use', ((value) ? this.item.commerce.can_change_permitted_use = 1 : this.item.commerce.can_change_permitted_use = 0))" />
              </div>
              <div v-if="this.item.object_type == 4 && this.inArray(this.item.commercial_type, [2, 4])"
                   class="row-fluid">
                <div class="fs-2 my-3">Лифты</div>
                <div class="row row-cols-1">
                  <div class="col">
                    <InputNumber inputLabel="Грузовой:"
                                 inputId="commerce_lifts_cargo_count"
                                 inputName="commerce_lifts_cargo_count"
                                 ref="commerce.lifts_cargo_count"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="шт."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.lifts_cargo_count : null"
                                 @setValue="(value) => this.setAndValidate('commerce.lifts_cargo_count', value)" />
                  </div>
                  <div class="col mb-3">
                    <InputNumber inputLabel="Грузоподъемность:"
                                 inputId="commerce_lifts_cargo_carrying"
                                 inputName="commerce_lifts_cargo_carrying"
                                 ref="commerce.lifts_cargo_carrying"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="т."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.lifts_cargo_carrying : null"
                                 @setValue="(value) => this.setAndValidate('commerce.lifts_cargo_carrying', value)" />
                  </div>
                </div>
                <div class="row row-cols-1">
                  <div class="col">
                    <InputNumber inputLabel="Тельфер:"
                                 inputId="commerce_lifts_telfer_count"
                                 inputName="commerce_lifts_telfer_count"
                                 ref="commerce.lifts_telfer_count"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="шт."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.lifts_telfer_count : null"
                                 @setValue="(value) => this.setAndValidate('commerce.lifts_telfer_count', value)" />
                  </div>
                  <div class="col mb-3">
                    <InputNumber inputLabel="Грузоподъемность:"
                                 inputId="commerce_lifts_telfer_carrying"
                                 inputName="commerce_lifts_telfer_carrying"
                                 ref="commerce.lifts_telfer_carrying"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="т."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.lifts_telfer_carrying : null"
                                 @setValue="(value) => this.setAndValidate('commerce.lifts_telfer_carrying', value)" />
                  </div>
                </div>
                <div class="row row-cols-1">
                  <div class="col">
                    <InputNumber inputLabel="Пассажирский:"
                                 inputId="commerce_lifts_passenger_count"
                                 inputName="commerce_lifts_passenger_count"
                                 ref="commerce.lifts_passenger_count"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="шт."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.lifts_passenger_count : null"
                                 @setValue="(value) => this.setAndValidate('commerce.lifts_passenger_count', value)" />
                  </div>
                  <div class="col mb-3">
                    <InputNumber inputLabel="Грузоподъемность:"
                                 inputId="commerce_lifts_passenger_carrying"
                                 inputName="commerce_lifts_passenger_carrying"
                                 ref="commerce.lifts_passenger_carrying"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="т."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.lifts_passenger_carrying : null"
                                 @setValue="(value) => this.setAndValidate('commerce.lifts_passenger_carrying', value)" />
                  </div>
                </div>
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 3, 5, 6, 7, 8, 9])"
                   class="row-fluid">
                <div class="fs-2 my-3">Крановое оборудование</div>
                <div class="row row-cols-1">
                  <div class="col">
                    <InputNumber inputLabel="Мостовой кран:"
                                 inputId="commerce_cranes_overhead_count"
                                 inputName="commerce_cranes_overhead_count"
                                 ref="commerce.cranes_overhead_count"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="шт."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.cranes_overhead_count : null"
                                 @setValue="(value) => this.setAndValidate('commerce.cranes_overhead_count', value)" />
                  </div>
                  <div class="col mb-3">
                    <InputNumber inputLabel="Грузоподъемность:"
                                 inputId="commerce_cranes_overhead_carrying"
                                 inputName="commerce_cranes_overhead_carrying"
                                 ref="commerce.cranes_overhead_carrying"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="т."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.cranes_overhead_carrying : null"
                                 @setValue="(value) => this.setAndValidate('commerce.cranes_overhead_carrying', value)" />
                  </div>
                </div>
                <div class="row row-cols-1">
                  <div class="col">
                    <InputNumber inputLabel="Кран-балка:"
                                 inputId="commerce_cranes_cathead_count"
                                 inputName="commerce_cranes_cathead_count"
                                 ref="commerce.cranes_cathead_count"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="шт."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.cranes_cathead_count : null"
                                 @setValue="(value) => this.setAndValidate('commerce.cranes_cathead_count', value)" />
                  </div>
                  <div class="col mb-3">
                    <InputNumber inputLabel="Грузоподъемность:"
                                 inputId="commerce_cranes_cathead_carrying"
                                 inputName="commerce_cranes_cathead_carrying"
                                 ref="commerce.cranes_cathead_carrying"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="т."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.cranes_cathead_carrying : null"
                                 @setValue="(value) => this.setAndValidate('commerce.cranes_cathead_carrying', value)" />
                  </div>
                </div>
                <div class="row row-cols-1">
                  <div class="col">
                    <InputNumber inputLabel="Ж/д кран:"
                                 inputId="commerce_cranes_railway_count"
                                 inputName="commerce_cranes_railway_count"
                                 ref="commerce.cranes_railway_count"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="шт."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.cranes_railway_count : null"
                                 @setValue="(value) => this.setAndValidate('commerce.cranes_railway_count', value)" />
                  </div>
                  <div class="col mb-3">
                    <InputNumber inputLabel="Грузоподъемность:"
                                 inputId="commerce_cranes_railway_carrying"
                                 inputName="commerce_cranes_railway_carrying"
                                 ref="commerce.cranes_railway_carrying"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="т."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.cranes_railway_carrying : null"
                                 @setValue="(value) => this.setAndValidate('commerce.cranes_railway_carrying', value)" />
                  </div>
                </div>
                <div class="row row-cols-1">
                  <div class="col">
                    <InputNumber inputLabel="Козловой кран:"
                                 inputId="commerce_cranes_gantry_count"
                                 inputName="commerce_cranes_gantry_count"
                                 ref="commerce.cranes_gantry_count"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="шт."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.cranes_gantry_count : null"
                                 @setValue="(value) => this.setAndValidate('commerce.cranes_gantry_count', value)" />
                  </div>
                  <div class="col mb-3">
                    <InputNumber inputLabel="Грузоподъемность:"
                                 inputId="commerce_cranes_gantry_carrying"
                                 inputName="commerce_cranes_gantry_carrying"
                                 ref="commerce.cranes_gantry_carrying"
                                 inputClass="rounded-1 mb-10"
                                 inputGroupClass="col w-50"
                                 inputWrapClass="row row-cols-2 flex-nowrap align-items-center mb-2"
                                 inputLabelClass="col w-50 text-dark-gray"
                                 inputAppend="т."
                                 :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.cranes_gantry_carrying : null"
                                 @setValue="(value) => this.setAndValidate('commerce.cranes_gantry_carrying', value)" />
                  </div>
                </div>
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 7, 8])" class="col mb-3">
                <Switcher inputId="commerce_is_investproject"
                          inputName="commerce_is_investproject"
                          ref="commerce.is_investproject"
                          inputLabel="Инвестпроект"
                          :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.is_investproject : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('commerce.is_investproject', value)" />
                <Switcher inputId="commerce_is_encumbrances"
                          inputName="commerce_is_encumbrances"
                          ref="commerce.is_encumbrances"
                          inputLabel="Наличие обременения"
                          :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.is_encumbrances : false"
                          inputClass="px-0"
                          inputWrapClass="flex-column mb-3"
                          @setValue="(value) => this.setAndValidate('commerce.is_encumbrances', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 7, 8])" class="col mb-3">
                <InputSelect inputId="commerce_electricity_id"
                             inputLabel="Электричество:"
                             inputName="commerce_electricity_id"
                             ref="commerce.electricity_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.electricity_id : null"
                             inputClass="rounded-1"
                             inputWrapClass="flex-column mb-3"
                             inputUsePlaceholder="true"
                             :inputOptions="this.commerce_electricity_list"
                             @setValue="(value) => this.setAndValidate('commerce.electricity_id', value)" />
                <InputCheckbox inputId="commerce_can_electricity"
                               inputLabel="- можно подключить"
                               inputName="commerce_can_electricity"
                               ref="commerce_can_electricity"
                               :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.can_electricity : false"
                               @setValue="(value) => this.setAndValidate('commerce.can_electricity', ((value) ? this.item.commerce.can_electricity = 1 : this.item.commerce.can_electricity = 0))" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 7, 8])" class="col mb-3">
                <InputSelect inputId="commerce_gas_id"
                             inputLabel="Газ:"
                             inputName="commerce_gas_id"
                             ref="commerce.gas_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.gas_id : null"
                             inputClass="rounded-1"
                             inputWrapClass="flex-column mb-3"
                             inputUsePlaceholder="true"
                             :inputOptions="this.commerce_gas_list"
                             @setValue="(value) => this.setAndValidate('commerce.gas_id', value)" />
                <InputCheckbox inputId="commerce_can_gas"
                               inputLabel="- можно подключить"
                               inputName="commerce_can_gas"
                               ref="commerce_can_gas"
                               :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.can_gas : false"
                               @setValue="(value) => this.setAndValidate('commerce.can_gas', ((value) ? this.item.commerce.can_gas = 1 : this.item.commerce.can_gas = 0))" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 7, 8])" class="col mb-3">
                <InputSelect inputId="commerce_water_id"
                             inputLabel="Вода:"
                             inputName="commerce_water_id"
                             ref="commerce.water_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.water_id : null"
                             inputClass="rounded-1"
                             inputWrapClass="flex-column mb-3"
                             inputUsePlaceholder="true"
                             :inputOptions="this.commerce_water_list"
                             @setValue="(value) => this.setAndValidate('commerce.water_id', value)" />
                <InputCheckbox inputId="commerce_can_water"
                               inputLabel="- можно подключить"
                               inputName="commerce_can_water"
                               ref="commerce_can_water"
                               :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.can_water : false"
                               @setValue="(value) => this.setAndValidate('commerce.can_water', ((value) ? this.item.commerce.can_water = 1 : this.item.commerce.can_water = 0))" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 7, 8])" class="col mb-3">
                <InputSelect inputId="commerce_sewage_id"
                             inputLabel="Канализация:"
                             inputName="commerce_sewage_id"
                             ref="commerce.sewage_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.sewage_id : null"
                             inputClass="rounded-1"
                             inputWrapClass="flex-column mb-3"
                             inputUsePlaceholder="true"
                             :inputOptions="this.commerce_sewage_list"
                             @setValue="(value) => this.setAndValidate('commerce.sewage_id', value)" />
                <InputCheckbox inputId="commerce_can_sewage"
                               inputLabel="- можно подключить"
                               inputName="commerce_can_sewage"
                               ref="commerce_can_sewage"
                               :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.can_sewage : false"
                               @setValue="(value) => this.setAndValidate('commerce.can_sewage', ((value) ? this.item.commerce.can_sewage = 1 : this.item.commerce.can_sewage = 0))" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 7, 8])" class="col mb-3">
                <InputSelect inputId="commerce_driveways_id"
                             inputLabel="Подъездные пути:"
                             inputName="commerce_driveways_id"
                             ref="commerce.driveways_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.driveways_id : null"
                             inputClass="rounded-1"
                             inputWrapClass="flex-column mb-3"
                             inputUsePlaceholder="true"
                             :inputOptions="this.commerce_driveways_list"
                             @setValue="(value) => this.setAndValidate('commerce.driveways_id', value)" />

              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 3, 5, 6, 7, 8, 9])" class="col mb-3">
                <SelectBox inputId="commerce_services"
                           inputName="commerce_services"
                           inputLabel="Дополнительные услуги:"
                           ref="commerce.services"
                           :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.services : null"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-2"
                           :inputOptions="this.commerce_services_list"
                           inputSearch="true"
                           inputMultiple="true"
                           @setValue="(value) => this.setAndValidate('commerce.services', value)" />
              </div>
            </div>
          </div>

          <!-- О здании (коммерция) -->
          <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [9])" class="row">
            <div class="fs-2 my-3">О здании</div>
            <div class="row-fluid row-cols-1">
              <div class="col">
                <InputText inputId="building_title"
                           inputLabel="Название:"
                           inputName="building_title"
                           ref="building.title"
                           :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.title : null"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-2"
                           @setValue="(value) => this.setAndValidate('building.title', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 9])" class="col">
                <InputNumber inputId="building_year"
                             inputLabel="Год постройки:"
                             inputName="building_year"
                             ref="building.year"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.year : null"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             @setValue="(value) => this.setAndValidate('building.year', value)" />
              </div>
              <div v-if="!this.inArray(this.item.commercial_type, [7, 9])" class="col">
                <InputSelect inputId="building_type_id"
                             inputLabel="Тип здания:"
                             inputName="building_type_id"
                             ref="building.type_id"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.type_id : null"
                             :inputOptions="this.building_types_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('building.type_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 9])" class="col">
                <InputSelect inputId="building_class_id"
                             inputLabel="Класс здания:"
                             inputName="building_class_id"
                             ref="building.class_id"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.class_id : null"
                             :inputOptions="this.building_classes_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('building.class_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 9])" class="col">
                <InputNumber inputId="building_area_size"
                             inputLabel="Площадь здания:"
                             inputName="building_area_size"
                             ref="building.area_size"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.area_size : null"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             @setValue="(value) => this.setAndValidate('building.area_size', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 7, 9, 5])" class="col">
                <InputSelect inputId="commerce_houseline_type_id"
                             inputLabel="Линия домов:"
                             inputName="commerce_houseline_type_id"
                             ref="commerce.houseline_type_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.houseline_type_id : null"
                             :inputOptions="this.houseline_types_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('commerce.houseline_type_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 9])" class="col">
                <InputSelect inputId="building_category_id"
                             inputLabel="Категория:"
                             inputName="building_category_id"
                             ref="building.category_id"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.category_id : null"
                             :inputOptions="this.building_categories_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('building.category_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 9])" class="col">
                <InputText inputId="building_developer_company"
                           inputLabel="Девелопер:"
                           inputName="building_developer_company"
                           ref="building.developer_company"
                           :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.developer_company : null"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-2"
                           @setValue="(value) => this.setAndValidate('building.developer_company', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7, 9])" class="col">
                <InputText inputId="building_management_company"
                           inputLabel="Управляющая компания:"
                           inputName="building_management_company"
                           ref="building.management_company"
                           :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.management_company : null"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-2"
                           @setValue="(value) => this.setAndValidate('building.management_company', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [9])" class="col">
                <InputSelect inputId="commerce_entrance_id"
                             inputLabel="Вход:"
                             inputName="commerce_entrance_id"
                             ref="commerce.entrance_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.entrance_id : null"
                             :inputOptions="this.entrance_types_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('commerce.entrance_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7])" class="col">
                <InputSelect inputId="building_ventilation_id"
                             inputLabel="Вентиляция:"
                             inputName="building_ventilation_id"
                             ref="building.ventilation_id"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.ventilation_id : null"
                             :inputOptions="this.building_ventilations_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('building.ventilation_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7])" class="col">
                <InputSelect inputId="building_conditioning_id"
                             inputLabel="Кондиционирование:"
                             inputName="building_conditioning_id"
                             ref="building.conditioning_id"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.conditioning_id : null"
                             :inputOptions="this.building_conditioning_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('building.conditioning_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7])" class="col">
                <InputSelect inputId="building_heating_id"
                             inputLabel="Отопление:"
                             inputName="building_heating_id"
                             ref="building.heating_id"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.heating_id : null"
                             :inputOptions="this.building_heating_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('building.heating_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [7])" class="col">
                <InputSelect inputId="building_firefighting_id"
                             inputLabel="Система пожаротушения:"
                             inputName="building_firefighting_id"
                             ref="building.firefighting_id"
                             :inputValue="(!this.isEmpty(this.item.building)) ? this.item.building.firefighting_id : null"
                             :inputOptions="this.building_firefighting_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('building.firefighting_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 8, 9])" class="col">
                <InputText inputId="gsk_title"
                           inputLabel="ГСК:"
                           inputName="gsk_title"
                           ref="gsk_title"
                           :inputValue="this.item.gsk_title ?? null"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-2"
                           @setValue="(value) => this.setAndValidate('gsk_title', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 8, 9])" class="col">
                <InputSelect inputId="garage_id"
                             inputLabel="Тип:"
                             inputName="garage_id"
                             ref="garage_id"
                             :inputValue="this.item.garage_id ?? null"
                             :inputOptions="this.garages_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('garage_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 8, 9]) && this.item.garage_id == 2" class="col">
                <InputSelect inputId="garage_type_id"
                             inputLabel="Тип гаража:"
                             inputName="garage_type_id"
                             ref="garage_type_id"
                             :inputValue="this.item.garage_type_id ?? null"
                             :inputOptions="this.garages_types_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('garage_type_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 8, 9]) && this.item.garage_id == 3" class="col">
                <InputSelect inputId="box_type_id"
                             inputLabel="Тип постройки:"
                             inputName="box_type_id"
                             ref="box_type_id"
                             :inputValue="this.item.box_type_id ?? null"
                             :inputOptions="this.boxes_types_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('box_type_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 8, 9])" class="col">
                <InputSelect inputId="commerce_status_id"
                             inputLabel="Статус:"
                             inputName="commerce_status_id"
                             ref="commerce.status_id"
                             :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.status_id : null"
                             :inputOptions="this.commerce_status_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputUsePlaceholder="true"
                             @setValue="(value) => this.setAndValidate('commerce.status_id', value)" />
              </div>
              <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [1, 2, 3, 4, 5, 6, 8, 9])" class="col">
                <SelectBox inputId="commerce_specifications"
                           inputLabel="Технические характеристики:"
                           inputName="commerce_specifications"
                           ref="commerce.specifications"
                           :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.specifications : null"
                           :inputOptions="this.commerce_specifications_list"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-2"
                           inputSearch="true"
                           inputMultiple="true"
                           @setValue="(value) => this.setAndValidate('commerce.specifications', value)" />
              </div>
            </div>
          </div>

          <!-- Инфраструктура (коммерция) -->
          <div v-if="this.item.object_type == 4 && !this.inArray(this.item.commercial_type, [5, 6, 8])" class="row">
            <div class="fs-2 my-3">Инфраструктура</div>
            <div class="row-fluid row-cols-1">
              <div class="col mb-3">
                <SelectBox inputId="commerce_infrastructure"
                           :inputLabel="false"
                           inputName="commerce_infrastructure"
                           ref="commerce.infrastructure"
                           :inputValue="(!this.isEmpty(this.item.commerce)) ? this.item.commerce.infrastructure : null"
                           :inputOptions="this.infrastructures_list"
                           inputClass="rounded-1 p-2"
                           inputWrapClass="flex-column mb-2"
                           inputSearch="true"
                           inputMultiple="true"
                           @setValue="(value) => this.setAndValidate('commerce.infrastructure', value)" />
              </div>
            </div>
          </div>

          <!-- Данные по новостройкам -->
         <!-- <div v-if="!this.isEmpty(this.item.deal_type)">
            <div v-if="this.item.operation_type == 2 && this.inArray(this.item.deal_type, [5, 6])" class="row">
              <div class="fs-2 my-3">Данные по новостройкам</div>
              <div class="row-fluid row-cols-1">
                <div class="col">
                  <InputText inputId="newbuildings_documentation_url"
                             inputLabel="Документация:"
                             inputName="newbuildings_documentation_url"
                             ref="newbuildings.documentation_url"
                             :inputValue="(!this.isEmpty(this.item.newbuildings)) ? this.item.newbuildings.documentation_url : null"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             @setValue="(value) => this.setAndValidate('newbuildings.documentation_url', value)"/>
                </div>
                <div class="col">
                  <InputSelect inputId="newbuildings_sale_type_id"
                               inputLabel="Тип продажи:"
                               inputName="newbuildings_sale_type_id"
                               ref="newbuildings.sale_type_id"
                               :inputValue="(!this.isEmpty(this.item.newbuildings)) ? this.item.newbuildings.sale_type_id : null"
                               :inputOptions="this.sale_types_list"
                               inputClass="rounded-1 p-2"
                               inputWrapClass="flex-column mb-2"
                               inputUsePlaceholder="true"
                               @setValue="(value) => this.setAndValidate('newbuildings.sale_type_id', value)"/>
                </div>
                <div class="col">
                  <SelectBox inputId="newbuildings_residential_id"
                             inputLabel="ЖК:"
                             inputName="newbuildings_residential_id"
                             ref="newbuildings.residential_id"
                             :inputValue="(!this.isEmpty(this.item.newbuildings)) ? this.item.newbuildings.residential_id : null"
                             :inputOptions="this.newbuildings_list"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             inputSearch="true"
                             inputLazyLoad="true"
                             inputRequired="true"
                             @searchInput="(term) => this.newbuildings_query = term"
                             @endOfList="(length) => this.newbuildings_offset = length"
                             @setValue="(value) => this.setAndValidate('newbuildings.residential_id', value)" />
                </div>
                <div class="col">
                  <InputSelect inputId="newbuildings_room_decoration_id"
                               inputLabel="Отделка помещения:"
                               inputName="newbuildings_room_decoration_id"
                               ref="newbuildings.room_decoration_id"
                               :inputValue="(!this.isEmpty(this.item.newbuildings)) ? this.item.newbuildings.room_decoration_id : null"
                               :inputOptions="this.decoration_types_list"
                               inputClass="rounded-1 p-2"
                               inputWrapClass="flex-column mb-2"
                               inputUsePlaceholder="true"
                               @setValue="(value) => this.setAndValidate('newbuildings.room_decoration_id', value)"/>
                </div>
                <div class="col">
                  <InputSelect inputId="newbuildings_flat_type_id"
                               inputLabel="Долевое строительство:"
                               inputName="newbuildings_flat_type_id"
                               ref="newbuildings.flat_type_id"
                               :inputValue="(!this.isEmpty(this.item.newbuildings)) ? this.item.newbuildings.flat_type_id : null"
                               :inputOptions="this.flat_types_list"
                               inputClass="rounded-1 p-2"
                               inputWrapClass="flex-column mb-2"
                               inputUsePlaceholder="true"
                               @setValue="(value) => this.setAndValidate('newbuildings.flat_type_id', value)"/>
                </div>
                <div class="col">
                  <DateTimePicker inputId="newbuildings_end_date"
                                  inputLabel="Срок сдачи:"
                                  inputName="newbuildings_end_date"
                                  ref="newbuildings.end_date"
                                  :inputValue="(!this.isEmpty(this.item.newbuildings)) ? this.item.newbuildings.end_date : null"
                                  inputClass="rounded-1 p-2"
                                  inputRequired="true"
                                  displayFormat="DD.MM.YYYY"
                                  inputWrapClass="flex-column mb-2"
                                  @setValue="(value) => this.setAndValidate('newbuildings.end_date', value)" />
                </div>
                <div class="col">
                  <InputSelect inputId="investor_type_id"
                               inputLabel="Тип дольщика:"
                               inputName="investor_type_id"
                               ref="investor.type_id"
                               :inputValue="(!this.isEmpty(this.item.investor)) ? this.item.investor.type_id : null"
                               :inputOptions="this.investor_types_list"
                               inputClass="rounded-1 p-2"
                               inputWrapClass="flex-column mb-2"
                               inputUsePlaceholder="true"
                               @setValue="(value) => this.setAndValidate('investor.type_id', value)"/>
                </div>
                <div class="col">
                  <InputText inputId="investor_last_name"
                             inputLabel="Фамилия дольщика:"
                             inputName="investor_last_name"
                             ref="investor.last_name"
                             :inputValue="(!this.isEmpty(this.item.investor)) ? this.item.investor.last_name : null"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             @setValue="(value) => this.setAndValidate('investor.last_name', value)"/>
                </div>
                <div class="col">
                  <InputText inputId="investor_first_name"
                             inputLabel="Имя дольщика:"
                             inputName="investor_first_name"
                             ref="investor.first_name"
                             :inputValue="(!this.isEmpty(this.item.investor)) ? this.item.investor.first_name : null"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             @setValue="(value) => this.setAndValidate('investor.first_name', value)"/>
                </div>
                <div class="col">
                  <InputText inputId="investor_second_name"
                             inputLabel="Отчество дольщика:"
                             inputName="investor_second_name"
                             ref="investor.second_name"
                             :inputValue="(!this.isEmpty(this.item.investor)) ? this.item.investor.second_name : null"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             @setValue="(value) => this.setAndValidate('investor.second_name', value)"/>
                </div>
                <div class="col">
                  <InputText inputId="investor_inn"
                             inputLabel="ИНН дольщика:"
                             inputName="investor_inn"
                             ref="investor.inn"
                             :inputValue="(!this.isEmpty(this.item.investor)) ? this.item.investor.inn : null"
                             inputClass="rounded-1 p-2"
                             inputWrapClass="flex-column mb-2"
                             @setValue="(value) => this.setAndValidate('investor.inn', value)"/>
                </div>
              </div>
            </div>
          </div>-->

          <!-- Информация о доме -->
          <!--<div class="col" v-if="this.item.operation_type == 2 && !this.inArray(this.item.object_type, [4, 7])">
            <div class="form-label text-font-primary fs-1 mb-3">Информация о доме</div>
            <InputSelect v-if="this.inArray(this.item.object_type, [1, 2, 5])"
                         inputId="house_type"
                         inputLabel="Тип дома:"
                         inputName="house_type"
                         ref="house_type"
                         :inputValue="this.item.house_type"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputRequired="!this.inArray(this.item.object_type, [3, 5, 7, 4])"
                         :inputOptions="this.getHouseTypes()"
                         @setValue="(value) => this.setAndValidate('house_type', value)" />
            <InputSelect v-if="this.inArray(this.item.object_type, [1, 2, 5])"
                         inputId="house_material_type"
                         inputLabel="Материал дома:"
                         inputName="house_material_type"
                         ref="house_material_type"
                         :inputValue="this.item.house_material_type"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         :inputRequired="!this.inArray(this.item.object_type, [3, 5, 7, 4])"
                         :inputOptions="this.getHouseMaterialTypes()"
                         @setValue="(value) => this.setAndValidate('house_material_type', value)" />
            <InputNumber inputId="build_year"
                         inputLabel="Год постройки:"
                         inputName="build_year"
                         ref="build_year"
                         :inputValue="(!this.isEmpty(this.item.build_year)) ? this.item.build_year : null"
                         inputClass="rounded-1 p-2"
                         inputWrapClass="flex-column mb-2"
                         @setValue="(value) => this.setAndValidate('build_year', value)" />
          </div>-->

          <!-- Удобства -->
          <!--<div class="col" v-if="this.item.operation_type == 1 && this.item.object_type != 4 && this.item.object_type != 7">
            <div class="form-label text-font-primary fs-1 mb-3">Удобства</div>
            <div class="scroller scroller-x text-nowrap-mask">
              <div class="btn-group btn-group-check ps-1 pb-4" role="group" aria-label="Удобства">

                <input type="checkbox"
                       class="btn-check"
                       id="features_fridge"
                       ref="features.fridge"
                       :checked="(!this.isEmpty(this.item.features)) ? ((this.item.features.fridge) ? true : false) : false"
                       autocomplete="off"
                       @change="(value) => this.setAndValidate('features.fridge', value.target.checked)" />

                <label class="d-flex flex-column me-2 p-3" for="features_fridge">
                  <img :src="require('@/assets/icons/fridge-active-icon.svg')" class="m-auto mt-2" alt="Холодильник" width="38" />
                  <span class="text-dark-gray fs-5 lh-base mt-2 mb-auto">Холодильник</span>
                </label>

                <input type="checkbox"
                       class="btn-check"
                       id="features_washer"
                       ref="features.washer"
                       :checked="(!this.isEmpty(this.item.features)) ? ((this.item.features.washer) ? true : false) : false"
                       autocomplete="off"
                       @change="(value) => this.setAndValidate('features.washer', value.target.checked)" />

                <label class="d-flex flex-column me-2 p-3" for="features_washer">
                  <img :src="require('@/assets/icons/washer-active-icon.svg')" class="m-auto mt-2" alt="Стиральная машина" width="38" />
                  <span class="text-dark-gray fs-5 lh-base mt-2 mb-auto">Стиральная машина</span>
                </label>

                <input type="checkbox"
                       class="btn-check"
                       id="features_tv"
                       ref="features.tv"
                       :checked="(!this.isEmpty(this.item.features)) ? ((this.item.features.tv) ? true : false) : false"
                       autocomplete="off"
                       @change="(value) => this.setAndValidate('features.tv', value.target.checked)" />

                <label class="d-flex flex-column me-2 p-3" for="features_tv">
                  <img :src="require('@/assets/icons/tv-active-icon.svg')" class="m-auto mt-2" alt="Телевизор" width="38" />
                  <span class="text-dark-gray fs-5 lh-base mt-2 mb-auto">Телевизор</span>
                </label>

                <input type="checkbox"
                       class="btn-check"
                       id="features_furniture"
                       ref="features.furniture"
                       :checked="(!this.isEmpty(this.item.features)) ? ((this.item.features.furniture) ? true : false) : false"
                       autocomplete="off"
                       @change="(value) => this.setAndValidate('features.furniture', value.target.checked)" />

                <label class="d-flex flex-column me-2 p-3" for="features_furniture">
                  <img :src="require('@/assets/icons/furniture-active-icon.svg')" class="m-auto mt-2" alt="Мебель" width="38" />
                  <span class="text-dark-gray fs-5 lh-base mt-2 mb-auto">Мебель</span>
                </label>

              </div>
            </div>
          </div>-->

          <!-- Дополнительные поля -->
          <div class="row">
            <div class="fs-2 my-3">Дополнительные поля</div>
            <div class="col mb-3">
              <CustomFields v-if="!this.isEmpty(this.custom_fields_list)"
                            ref="customFields"
                            :customFields="this.custom_fields_list"
                            :customValues="(!this.isEmpty(this.item.fields)) ? this.item.fields : null"
                            @inputInvalid="(error) => this.setError('fields', error)"
                            @setValue="(value) => this.setAndValidate('fields.'+value.name, value.value)" />
              <div v-else>
                <EmptyList message="Поля не найдены" />
              </div>
              <div class="form-text d-flex fs-5">
                <img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
                Добавляйте дополнительные поля в карточку объекта
                из настроек профиля Вашего агенства, чтобы потом использовать
                их для выгрузки в XML-фид.
              </div>
            </div>
          </div>

          <!-- Для документов -->
          <div class="row">
            <div class="fs-2 my-3">Для документов</div>
            <div class="col mb-3">
              <div v-if="!this.isEmpty(this.variables_list)">
                <div v-for="(variable, key) in this.variables_list" :key="variable.id">
                  <InputText :inputId="'variables_' + key"
                             :inputName="'variables_' + key"
                             :ref="'variables_' + key"
                             :input-label="variable.label + ':'"
                             :input-value="this.item.variables[key] ?? this.variables_list[variable.id]['default']"
                             inputLabelClass="text-font-secondary mb-3"
                             inputClass="rounded-3 p-2"
                             inputWrapClass="mb-20"
                             @setValue="(value) => this.setAndValidate('variables.' + key, value)" />

                </div>
              </div>
              <div v-else>
                <EmptyList message="Переменные не найдены" />
              </div>

              <InnerFieldsForm :sourceId="this.item.id"
                               sectionId="objects"
                               @addVariables="(variables) => this.updateVariablesList(variables)" />

            </div>
          </div>

          <!-- Фотографии -->
          <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Фотографии</div>

            <ImageUploader ref="photos" inputId="photos" inputName="photos"
                           :inputObjectId="this.object_id"
                           :imagesList="this.item.photos"
                           :maxImages="40"
                           currentSection="photo"
                           :allowedMime="[
										'image/jpeg',
										'image/jpg',
										'image/png',
										'.heic',
										'.heif',
								   ]"
                           @inputInvalid="(error) => this.setError('photos', error)"
                           @onImageUploaded="(section, images) => this.imageUploaded(section, images)" />

            <div class="form-text d-flex fs-5 mb-3">
              <img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: 1px;">
              Необходимо для рекламы на бесплатных площадках (минимум 4 фотографии).
            </div>

            <Switcher inputId="add_watermark"
                      inputName="add_watermark"
                      ref="add_watermark"
                      inputLabel="Добавлять водяной знак"
                      :inputValue="(typeof this.item.add_watermark !== 'undefined') ? this.item.add_watermark : this.$store.getters.userInfo.auto_watermark"
                      :inputDisabled="(this.$store.getters.userInfo.watermark == null)"
                      inputClass="px-0 mb-3"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('add_watermark', value)" />

            <div v-if="(this.$store.getters.userInfo.watermark == null)"
                 class="form-text d-flex fs-5 mb-3">
              <img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex ms-0 me-2" width="20" height="20" />
              Водяной знак не установлен в настройках пользователя.
            </div>

          </div>

          <!-- Планировка -->
          <div class="col">
            <div class="form-label text-font-primary fs-1 mb-3">Планировка</div>

            <ImageUploader ref="plans" inputId="plans" inputName="plans"
                           :inputObjectId="this.object_id"
                           :imagesList="this.item.plans"
                           :maxImages="1"
                           currentSection="img_plan"
                           :allowedMime="[
										'image/jpeg',
										'image/jpg',
										'image/png',
										'.heic',
										'.heif',
								   ]"
                           @inputInvalid="(error) => this.setError('plans', error)"
                           @onImageUploaded="(section, images) => this.imageUploaded(section, images)" />

            <div class="form-text d-flex fs-5 mb-3">
              <img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
              Необходимо для формирования PDF-презентации.
            </div>

          </div>

          <!-- Доски объявлений -->
          <div class="row">
            <div class="fs-2 my-3">Доски объявлений</div>
            <div class="col">
              <Switcher inputId="use_in_advert"
                        inputName="use_in_advert"
                        ref="use_in_advert"
                        inputLabel="В продвижение"
                        :inputValue="this.item.use_in_advert"
                        inputClass="px-0 mb-3"
                        inputWrapClass="flex-column mb-3"
                        :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline fs-5 me-2', 'Info', [16, 16]) + 'При включенном переключателе ваш объект попадет в раздел Продвижение, откуда ответственный специалист сделает выгрузку на сторонние источники.'"
                        @setValue="(value) => this.setAndValidate('use_in_advert', value)" />
            </div>
          </div>

          <div class="row">
            <div class="col mt-2">
              <button
                  type="button"
                  class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
                  :disabled="this.is_form_processed || this.object_has_lock"
                  @click="this.deBounce(this.addEditObject(), 1000)"
                  v-text="(this.item.id) ? 'Сохранить' : 'Добавить'" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

	<GeoLocationModal @changeCoords="(coords) => this.changeCoords(coords)"
					  @saveCoords="(coords) => this.setCoords(coords)" />

	<ConfirmModal id="ConfirmRemoveFromAds"
				  ref="confirmRemoveFromAds"
				  title="Подтвердите действие"
				  text="Убрать объект из рекламы? Если показ в рекламе уже был начат, то все ротации по объекту будут остановлены."
				  fromSection="addEditObject"
				  @confirmed="this.item.use_in_advert = 0"
				  @notConfirmed="this.item.use_in_advert = 1"
				  btnText="Ok" />

	<ConfirmModal id="ConfirmGoBack"
				  ref="confirmGoBack"
				  title="Подтвердите действие"
				  text="Похоже Вы вносили изменения в форму. Вы действительно желаете закрыть её без сохранения!?"
				  fromSection="addEditObject"
				  @confirmed="this.goBack(true)"
				  btnText="Да" />

	<ResultsModal id="addEditObject"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"
				  successВutton="Ok"
				  :successAction="() => this.goBack()"/>

  <NewTaskModal ref="taskModalAddObject"
				modalId="taskModalAddObject"
					  :objectId="this.current_object_id"
					  :statusId="this.current_status_id"
					  :eventId="this.current_event_id"
					  :eventType="this.current_event_type"
					  :eventSection="this.current_section"
					  :isCheckOwner="Boolean(this.current_owner)" 
            podSection="1" 
					  @newTasksClosed="this.newEventReset()" 
					  @updateDateCall="(date_call) => this.updateDateCall(date_call)"
            />


</template>

<style lang="scss">
.lw-control-group {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
}

.flex-wrap, .lw-control-group, .lw-section-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.lw-controls-with-desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px 20px;
    width: 100%;
}
.lw-controls-with-desc--bordered {
    border-radius: 6px;
    padding: 10px;
    border: 1px solid #dedede;
    
}

.lw-controls-with-desc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px 20px;
    width: 100%;
}

.lw-controls-with-desc > span {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
}
.lw-flex-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.lw-control-group-title {
    display: inline-block;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 25px;
}

.lw-main-change-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    border: none;
    padding: 8px 12px;
    color: #ffffff;
    background-color: #4caf50;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 4px;
}

</style>

<script>
import CommonService from "../services/CommonService";

import InputText from "../components/inputs/InputText.vue";
//import TextArea from "../components/inputs/TextArea.vue";
import InputCheckbox from "../components/inputs/InputCheckbox.vue";
//import InputRadio from "../components/inputs/InputRadio.vue";
import Switcher from "../components/inputs/Switcher.vue";
import InputNumber from "../components/inputs/InputNumber.vue";
import InputSelect from "../components/inputs/InputSelect.vue";
import SelectBox from "../components/inputs/SelectBox.vue";
//import InputRange from "@/components/inputs/InputRange";
import ToggleButtons from "@/components/inputs/ToggleButtons";

import GeoLocationModal from "@/components/modals/GeoLocationModal";

import * as bootstrap from "bootstrap";
import api from "@/api";
import axios from "axios";
import Editor from "@/components/inputs/Editor";
import CustomFields from "@/components/common/CustomFieldsList";
import ImageUploader from "@/components/common/ImageUploader";
import ConfirmModal from "@/components/modals/ConfirmModal";
import InnerFieldsForm from "@/components/forms/InnerFieldsForm";
import InputRadio from "@/components/inputs/InputRadio";
import ResultsModal from "@/components/modals/ResultsModal";
import EmptyList from "@/components/common/EmptyList";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import Loader from "@/components/common/Loader";
import NewTaskModal from "@/components/modals/NewTaskModal"
import TextArea from "@/components/inputs/TextArea.vue";

export default {
  name: "addObject",
  props: {
    returnPath: {type: String},
    objectId: {type: [ String, Number ]},
    clientId: {type: [ String, Number ]},
    restoreForm: {type: [ Boolean ]},
  },
  data() {
    return {
      current_object_id: null,
      current_status_id: null,
      current_event_id: null,
      current_event_type: null,
      current_section: null,
      current_owner: null,
      is_update_avito: false,
      title: {
        default: "Новый объект",
        edit: "Редактирование объекта",
      },
      object_id: (typeof this.objectId !== "undefined" && this.objectId) ? this.objectId : null,
      client_id: (typeof this.clientId !== "undefined" && this.clientId) ? this.clientId : null,
      restore_form: (typeof this.restoreForm !== "undefined" && this.restoreForm) ? true : false,
      is_form_processed: false,
      item: {
        operation_type: null,
        object_type: null,
        lease_type: null,
        commercial_type: null,
        address: {
          country_id: null,
          region_id: null,
          full: null,
          kladr_id: null,
        },
        geo : {
          lat: null,
          lng: null,
        },
        title: null,
        prices: {
          amount: null,
          currency: 0,
          price_avito: 0,
          is_commission: null,
          commission: null,
          commission_agent: null,
          amount_commission: null,
        },
        commission_agency: null,
        deal_type: null,
        tax_number: null,
        floors: {
          num: null,
          max: null,
        },
        areas: {
          full: null,
          kitchen: null,
          live: null,
          land: null,
        },
        metro: {
          type: null,
          stations: [],
        },
        owners: [],
        newbuildings: {
          residential_id: null,
          end_date: null,
        },
        rooms_count: null,
        rooms_count_all: null,
        avito_room_type_id: null,
        house_type: null,
        house_material_type: null,
        house_category_id: null,
        build_year: null,
        photos: [],
        plans: [],
        parts_area: [],
        fields: [],
        variables: [],
        add_watermark: false,
        use_in_advert: true,
        is_free_plan: false,
      },
      user_cant_edit: false,
      object_has_lock: false,
      is_form_changed: false,
      isEditAdressFormActive: false,
      isPriceStatsFormActive: false,
      custom_fields_list: [],
      custom_fields_dop_list: [],
      icons: {
        'info-icon': ''
      },
      status_list: [],
      area_analytics_list: [],
      search_query: '',
      clients_offset: 0,
      countries_list: null,
      regions_list: null,
      metro_list: null,
      to_metro_list: null,
      clients_list: [],
      variables_list: null,
      floor_materials_list: null,

      building_types_list: null,
      building_classes_list: null,
      houseline_types_list: null,
      building_categories_list: null,
      entrance_types_list: null,
      building_ventilations_list: null,
      building_conditioning_list: null,
      building_heating_list: null,
      building_firefighting_list: null,

      garages_list: null,
      garages_types_list: null,
      boxes_types_list: null,
      commerce_status_list: null,
      commerce_specifications_list: null,
      commerce_infrastructures_list: null,
      commerce_infrastructures2_list: null,
      commerce_infrastructures3_list: null,

      commerce_portal_list: null,
      commerce_purpose_list: null,
      commerce_land_categories_list: null,
      commerce_permitted_use_list: null,
      commerce_electricity_list: null,
      commerce_gas_list: null,
      commerce_water_list: null,
      commerce_sewage_list: null,
      commerce_driveways_list: null,
      commerce_services_list: null,

      legal_address_list: null,
      sale_types_list: null,
      newbuildings_list: null,
      decoration_types_list: null,
      flat_types_list: null,
      investor_types_list: null,
      layouts_list: null,
      wet_spots_list: null,
      conditions_list: null,
      access_list: null,
      property_types_list: null,
      rent_house_types_list: null,
      wall_types_list: null,
      house_wcs_types_list: null,
      land_purposes_types_list: null,
      shape_types_list: null,
      plumbing_types_list: null,
      gas_types_list: null,
      electricity_types_list: null,
      sewerage_types_list: null,
      relief_types_list: null,
      land_usage_types_list: null,
      lease_deposit_types_list: null,
      window_view_types_list: null,
      renovation_types_list: null,
      renovation_site_list: null,
      bathroom_types_list: null,
      hot_water_types_list: null,
      parking_types_list: null,
      parking_types1_list: null,
      parking_types2_list: null,
      parking_types3_list: null,
      vat_types_list: null,
      room_types_list: null,
      mebel_list: null,

      resultsModalState: {
        state: '',
        title: '',
        message: ''
      },
      user_region_id: null,
      address_variants: [],
      newbuildings_query: null,
      newbuildings_offset: 0,
      tags_list: [],
      errors: [],
      is_loading_process: true,
    };
  },
  methods: {
    openTask(){
				
				let _this = this;
				
					
						this.current_object_id = parseInt(this.item.id);
						this.current_status_id = this.item.status_object_id;
						this.current_event_id = parseInt(this.item.event_id);
						this.current_event_type = 'call_owner';
						this.current_section = 'objects';
						this.current_owner = true;
						
						setTimeout(function(){
							_this.$refs.taskModalAddObject.showModal();
						}, 200);
						
				
			},
			newEventReset() {
				this.current_object_id = null;
				this.current_status_id = null;
				this.current_event_id = null;
				this.current_event_type = null;
				this.current_section = null;
				this.current_owner = null;

				CommonService.log('debug', 'newEventReset()');

			},
      updateDateCall(date_call){
        //console.log(date_call);
        this.item.date_call = date_call.date;
        this.item.event_id = date_call.event_id;
      },

    goBack(forced) {
      if(this.is_update_avito){
        this.resultsModalState.state = null;
        this.resultsModalState.title = null;
      } else {
      if (!forced && !this.object_has_lock) {
        if (this.is_form_changed && this.isEmpty(this.$props.returnPath)) {
          let confirm = CommonService.getModal('addEditObjectConfirmGoBack');
          confirm.show();
        } else {

          let path = '/objects';
          if (!this.isEmpty(this.$props.returnPath))
            path = this.$props.returnPath;

          let query = [];
          if (!this.isEmpty(this.client_id))
            query.push('id=' + this.client_id)

          if (!this.isEmpty(this.item.id))
            query.push('object_id=' + this.item.id);

          if (this.inArray(this.$props.returnPath, [
            '/add-client',
            '/edit-client',
            '/add-requisition',
            '/edit-requisition'
          ])) {
            query.push('restore_form=true');
          }

          if (query.length > 0)
            path = path + '?' + query.join('&');

          if (!this.isEmpty(this.item.id))
            path = path + '#item_' + this.item.id.toString();

						this.$router.push(path);
					}
				} else {
					window.history.back();
				}
      }
			},
			toRoute(path) {
				this.$store.commit('setFormsState', { 'addObject': this.item });

				if (!this.isEmpty(this.item.id))
					path = path + '#item_' + this.item.id.toString();

				this.$router.push(path);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			deBounce(callback, timeout) {
				return CommonService.debounce(callback, timeout)
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatDate(format, datetime) {
				return CommonService.formatDateTime(datetime, 'ru', format);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			removeArea(index, value) {
				this.item.parts_area[index] = null;
				this.item.parts_area.slice(index, 1);
			},
			getObject() {

				this.emitter.emit("global.startGetObject", { ref: 'AddObject::getObject()', object_id: this.object_id });

				return api.get('/object', {
					params: {
						object_id: this.object_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getObject()::axios', {response: response.data});

					this.emitter.emit("global.endGetObject", { response: response });

					if (response.status == 200) {

						if (response.data.user_can_edit == false)
							this.user_cant_edit = true;

            this.object_has_lock = response.data.object_has_lock;

            if (!this.object_has_lock) {
              api.get('/objectLock', {
                params: {
                  object_id: this.object_id,
                }
              })
            }

						if (response.data.success && !this.isEmpty(response.data.item))
							return response.data.item;

					}
					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getObject()::axios', error);
					this.emitter.emit("global.endGetObject", { error: error });

				});
			},
			getClients() {
				return api.get('/clients')
				.then((response) => {

					CommonService.log('debug', 'getClients()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (!this.isEmpty(response.data.clients)) {
							response.data.clients.forEach((client) => {

								let contacts = [];
								if (client.email)
									contacts.push(client.email);

								if (client.phone)
									contacts.push(client.phone);

								if (!this.isEmpty(contacts))
									client.name = client.name + ' (' + contacts.join(', ') + ')';

								list.push({
									value: client.id,
									name: client.name,
								})
							});
						}

						this.$store.commit('setObjectClientsList', list);
						return list;
					}

					return {};
				}).catch(function (error) {

					CommonService.log('error', 'getClients()::axios', error);

				});
			},
      async getListStatus(){
        let params = {};
        if(this.object_id){
          params.object_id = this.object_id;
        } else {
          params.section = 'all'; 
        }
        return api.get('/object/statusLists',{
          params: params
        })
				.then((response) => {
                  //  console.log(response.data);
					CommonService.log('debug', 'getListStatus()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let listStatus = [];
						if (response.data.status_lists) {
							Object.entries(response.data.status_lists).forEach(([key, status]) => {
								listStatus.push({
									value: status.id.toString(),
									name: status.name
								});
							});
						}
             
                       
						return listStatus;
					} else {
						return {};
					}

                    
				}).catch(function (error) {

					CommonService.log('error', 'getListStatus()::axios', error);

				});
      },
      async getAreaAnalyticsList(){
        
        return api.get('/object/areaAnalyticsList')
				.then((response) => {
                  
					CommonService.log('debug', 'getAreaAnalyticsList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, area]) => {
								list.push({
									value: area.id.toString(),
									name: area.name
								});
							});
						}
           
                       
						return list;
					} else {
						return {};
					}

                    
				}).catch(function (error) {

					CommonService.log('error', 'getAreaAnalyticsList()::axios', error);

				});
      },
			async getClientsList(clients_ids) {

				let params = {
					filters: {},
					offset: this.clients_offset ?? 0,
					search_query: this.search_query,
					clients_ids: clients_ids ?? null
				};

				CommonService.log('debug', 'getClientsList()', params);

				return api.get('/common/clients', {
					params: params
				}).then((response) => {

					CommonService.log('debug', 'getClientsList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let clients = [];
						Object.entries(response.data.list).forEach(([key, option]) => {

							let client_name = option.name;

							let contacts = '';
							if (option.phone && option.phone !== '')
								contacts = option.phone;

							if (option.email && contacts !== '')
								contacts = contacts + ', ' + option.email;
							else if (option.email)
								contacts = option.email;

							if (contacts !== '')
								client_name = client_name + ' (' + contacts + ')';

							clients.push({
								value: option.id,
								name: client_name
							});
						});

						return clients;
					}

					return {};

				}).catch(function (error) {

					CommonService.log('error', 'getClientsList()::axios', error);

				});
			},
			async getNewBuildings(region_id, offset, search_query) {

				return api.get('/common/newbuildings', {
					params: {
						region_id: region_id,
						page: (((offset ?? 0)/10)+1) ?? 1,
						search: search_query ?? null
					}
				}).then((response) => {

					CommonService.log('debug', 'getNewBuildings()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getNewBuildings()::axios', error);

				});
			},
			getCustomFields() {
				return api.get('/objects/custom-fields', {
					params: {
						is_active: true,
            is_dop: 0
					}
				}).then((response) => {
          
					CommonService.log('debug', 'getCustomFields()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = {
							fields: response.data.fields,
							blocks: response.data.blocks,
							types: response.data.types,
						};
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getCustomFields()::axios', error);

				});
			},

      getCustomDopFields() {
				return api.get('/objects/custom-fields', {
					params: {
						is_active: true,
            is_dop: 1
					}
				}).then((response) => {
          
					CommonService.log('debug', 'getCustomDopFields()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = {
							fields: response.data.fields,
							blocks: response.data.blocks,
							types: response.data.types,
						};
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getCustomDopFields()::axios', error);

				});
			},
			processAddress(address) {

				CommonService.log('debug', 'processAddress()', {address: address});

				if (this.isEmpty(address))
					return null;

				if (address.area_type_full == "район")
					this.item.address.district = CommonService.arrayJoin([address.area, address.area_type], " ");
				else
					this.item.address.district = address.area;

				if (address.city_district)
					this.item.address.city_district = address.city_district;
				else if (address.settlement && (!address.area && address.city))
					this.item.address.city_district = CommonService.arrayJoin([address.settlement_type, address.settlement], " ");

				if (address.settlement_type_full == "микрорайон")
					this.item.address.city_district = CommonService.arrayJoin([address.settlement_type, address.settlement], " ");

				if (address.city)
					this.item.address.city = address.city;
				else
					this.item.address.city = CommonService.arrayJoin([address.settlement_type, address.settlement], " ");

				if (address.street_type_full == "шоссе" || address.street_type_full == "проезд" || address.street_type_full == "переулок")
					this.item.address.street = CommonService.arrayJoin([address.street, address.street_type], " ");
				else
					this.item.address.street = CommonService.arrayJoin([address.street_type, address.street], " ");

				if (address.house)
					this.item.address.house = address.house;
				else
					this.item.address.house = null;

				if (address.flat)
					this.item.address.flat = address.flat;
				

				if (address.block_type == "к" || address.block_type_full == "корпус")
					this.item.address.building = address.block;
				else
					this.item.address.building = null;

				if (address.block_type == "литера" || address.block_type_full == "литера")
					this.item.address.litera = address.block;
				else
					this.item.address.litera = null;

				if (address.block_type_full == "строение" && this.item.address.litera == null)
					this.item.address.litera = address.block;
				else
					this.item.address.litera = null;

        this.item.address.qc_geo = address.qc_geo;
        this.item.address.fias_level = address.fias_level;

        this.debounceAddress(address);

				return {...this.item.address, ...[this.geo_lat, this.geo_lng]};
			},
      checkAddressInYa(value) {
        if (this.item.address.qc_geo !== "0") {
          if (this.item.address.fias_level === "8" || this.item.address.fias_level === "7" || this.item.address.fias_level === "65") {
            api.get('/objects/getCoordinatesFromYandex', {
              params: {
                address: value
              }
            }).then((response) => {

              CommonService.log('debug', 'getCoordinatesFromYandex()::axios', {response: response.data});

              if (response.status == 200 && response.data.success) {
                let coords = response.data.point.split(' ');
                if (coords[0] && coords[1]) {
                  this.debounceAddress({geo_lat:coords[1], geo_lon: coords[0]});
                }
              } else {
                this.debounceAddress({geo_lat:null, geo_lon: null});
              }

            }).catch(function (error) {

              CommonService.log('error', 'getCoordinatesFromYandex()::axios', error);

            });
          } else {
            this.debounceAddress({geo_lat:null, geo_lon: null});
          }
        }
      },
      debounceAddress(address) {
        CommonService.debounce(() => {
          if (!this.isEmpty(address.geo_lat) && !this.isEmpty(address.geo_lon)) {
            this.geo_lat = address.geo_lat;
            this.geo_lng = address.geo_lon;
          } else {
            this.item.geo.lat = null;
            this.item.geo.lng = null;
            this.errors['address_full'] = {
              name: 'address_full',
              label: null,
              errors: ['Необходимо уточнить координаты объекта на карте']
            };
          }
        }, 1000);
      },
			getAddressVariants(address) {

				CommonService.log('debug', 'getAddressVariants()', {address: address});

				if (typeof address == "string" && !this.isEmpty(address)) {

					let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
					let token = this.$root.config.dadata.token;
					let config = {
						headers: {
							"Content-Type": "application/json",
							"Accept": "application/json",
							"Authorization": "Token " + token
						}
					};

					let query = {
						query: String(address),
						count: 50
					}

					let region_id = this.item.address.region_id ?? this.region_id;
					query.locations = [];
					query.restrict_value = false;
					if (region_id) {
						if (!this.isEmpty(this.regions_list)) {
							this.regions_list.forEach(region => {
								if (!this.isEmpty(region.kladr) && region.value == region_id) {
									region.kladr.forEach(id => {
										query.locations.push({
											kladr_id: id
										});
									});
								}
							});
						}
					} else {
						return [];
					}

					let data = JSON.stringify(query);
					axios.post(url, data, config).then((response) => {

						CommonService.log('debug', 'getAddressVariants()::axios', {response: response.data});

						let result = response.data;
						this.address_variants = [];
						if (!this.isEmpty(result.suggestions)) {

							let variants = result.suggestions.map((variant) => { return variant.value });
							if (!this.isEmpty(variants)) {

								variants.forEach((variant) => {
									this.address_variants.push({
										label: variant,
										value: variant,
									});
								});

								let dadata_icon = this.icon(require('@/assets/svg/dadata-logo.svg'), false, 'DaData.ru', ['auto', 18]);
								this.address_variants.push({
									label: '<a href="https://dadata.ru/" target="_blank" ref="https://m.crm.intermarkproperty.ru/">' + dadata_icon + '</a>',
									value: null,
								});
							}

							CommonService.log('debug', 'getAddressVariants()', this.address_variants);

						}
					}).catch(function (error) {

						CommonService.log('error', 'getAddressVariants()::axios', error);

					});
				}
			},
			getSuggestAddress(address) {

				CommonService.log('debug', 'getSuggestAddress()', {address: address});

				if (typeof address == "string" && !this.isEmpty(address)) {

					let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
					let token = this.$root.config.dadata.token;
					let config = {
						headers: {
							"Content-Type": "application/json",
							"Accept": "application/json",
							"Authorization": "Token " + token
						}
					};

					let query = {
						query: String(address),
						count: 1
					}

					let data = JSON.stringify(query);
					axios.post(url, data, config).then((response) => {

						CommonService.log('debug', 'getSuggestAddress()::axios', {response: response.data});

						let result = response.data;
						if (!this.isEmpty(result.suggestions[0])) {
							let data = result.suggestions[0].data;
							this.item.address.kladr_id = data.kladr_id;
							this.processAddress(data);
						} else {
							this.item.address.district = null;
							this.item.address.city_district = null;
							this.item.address.city = null;
							this.item.address.street = null;
							this.item.address.house = null;
							this.item.address.flat = null;
							this.item.address.building = null;
							this.item.address.litera = null;
							this.geo_lat = null;
							this.geo_lng = null;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getSuggestAddress()::axios', error);

					});
				}
			},
			getAddressByCoords(cords) {

				let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";
				let token = this.$root.config.dadata.token;
				let config = {
					mode: "cors",
					headers: {
						"Content-Type": "application/json",
						"Accept": "application/json",
						"Authorization": "Token " + token
					}
				};
				let data = JSON.stringify(cords);
				axios.post(url, data, config)
				.then((response) => {
					CommonService.log('debug', 'getAddressByCoords()::axios', {response: response.data});

					let result = response.data;
					if (result.suggestions[0]) {
						let data = result.suggestions[0].data;
						let address = result.suggestions[0].value;
						if (typeof address !== "undefined" && data.qc_geo == "0") {
							this.item.address.full = address;
							this.item.address.kladr_id = data.kladr_id;
							this.getSuggestAddress(this.item.address.full);
						}
					}
				}).catch(function (error) {

					CommonService.log('error', 'getAddressByCoords()::axios', error);

				});
			},
			changeCoords(coords) {

				CommonService.log('debug', 'changeCoords()', {coords: coords});

			},
			setCoords(coords) {

				CommonService.log('debug', 'setCoords()', {coords: coords});

				if (typeof coords.lat !== "undefined" && typeof coords.lng !== "undefined") {

					this.item.geo = {
						lat: coords.lat,
						lng: coords.lng,
					};
				}
			},
			getRegions() {

				if (!this.isEmpty(this.$store.state.regions_list))
					return this.$store.state.regions_list;
				else
					return api.get('/common/regions').then((response) => {

						CommonService.log('debug', 'getRegions()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name,
									kladr: option.kladr
								});
							});

							this.$store.commit('setRegionsList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getRegions()::axios', error);

					});
			},
			getTags() {

				return api.get('/common/tags').then((response) => {

					CommonService.log('debug', 'getTags()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						this.$store.commit('setTagsList', list);
						return list;
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTags()::axios', error);

				});
			},
			getOperationsTypes() {

				if (!this.isEmpty(this.$store.state.obj_operations_list))
					return this.$store.state.obj_operations_list;
				else
					return api.get('/common/types', {
						params: {
							section: 'operations'
						}
					}).then((response) => {

						CommonService.log('debug', 'getOperationsTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setObjectOperationsList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getOperationsTypes()::axios', error);

					});
			},
			getObjectTypes() {

				if (!this.isEmpty(this.$store.state.obj_types_list))
					return this.$store.state.obj_types_list;
				else
					return api.get('/common/types', {
						params: {
							section: 'objects'
						}
					}).then((response) => {

						CommonService.log('debug', 'getObjectTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setObjectTypesList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getObjectTypes()::axios', error);

					});
			},
			getLeaseTypes() {

				if (!this.isEmpty(this.$store.state.lease_list))
					return this.$store.state.lease_list;
				else
					return api.get('/common/types', {
						params: {
							section: 'lease'
						}
					}).then((response) => {

						CommonService.log('debug', 'getLeaseTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setLeaseList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getLeaseTypes()::axios', error);

					});
			},
			getCommercialTypes() {

				if (!this.isEmpty(this.$store.state.commercial_list))
					return this.$store.state.commercial_list;
				else
					return api.get('/common/commercial').then((response) => {

						CommonService.log('debug', 'getCommercialTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setCommercialList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getCommercialTypes()::axios', error);

					});
			},
			getAvitoRoomTypes() {

				if (!this.isEmpty(this.$store.state.avito_room_list))
					return this.$store.state.avito_room_list;
				else
					return api.get('/common/types', {
						params: {
							section: 'avito_room'
						}
					}).then((response) => {

						CommonService.log('debug', 'getAvitoRoomTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setAvitoRoomTypesList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getAvitoRoomTypes()::axios', error);

					});
			},
			getDealTypes() {

				if (!this.isEmpty(this.$store.state.deal_types_list))
					return this.$store.state.deal_types_list;
				else
					return api.get('/common/types', {
						params: {
							section: 'deal_type'
						}
					}).then((response) => {

						CommonService.log('debug', 'getDealTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setDealTypesList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getDealTypes()::axios', error);

					});
			},
			getFirstFloorTypes() {

				if (!this.isEmpty(this.$store.state.first_floor_list))
					return this.$store.state.first_floor_list;
				else
					return api.get('/common/types', {
						params: {
							section: 'first_floor'
						}
					}).then((response) => {

						CommonService.log('debug', 'getFirstFloorTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setFirstFloorList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getFirstFloorTypes()::axios', error);

					});
			},
			getHouseCategories() {

				if (!this.isEmpty(this.$store.state.house_categories_list))
					return this.$store.state.house_categories_list;
				else
					return api.get('/common/list', {
						params: {
							section: 'house_categories'
						}
					}).then((response) => {

						CommonService.log('debug', 'getHouseCategories()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setHouseCategoriesList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getHouseCategories()::axios', error);

					});
			},
			getHouseTypes() {

				if (!this.isEmpty(this.$store.state.house_types_list))
					return this.$store.state.house_types_list;
				else
					return api.get('/common/types', {
						params: {
							section: 'house_types'
						}
					}).then((response) => {

						CommonService.log('debug', 'getHouseTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setHouseTypesList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getHouseTypes()::axios', error);

					});
			},
			getHouseMaterialTypes() {

				if (!this.isEmpty(this.$store.state.house_materials_list))
					return this.$store.state.house_materials_list;
				else
					return api.get('/common/types', {
						params: {
							section: 'house_materials'
						}
					}).then((response) => {

						CommonService.log('debug', 'getHouseMaterialTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setHouseMaterialsList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getHouseMaterialTypes()::axios', error);

					});
			},
			getLayouts() {

				if (!this.isEmpty(this.$store.state.layouts_list))
					return this.$store.state.layouts_list;
				else
					return api.get('/common/layouts').then((response) => {

						CommonService.log('debug', 'getLayouts()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							this.$store.commit('setLayoutsList', list);
							return list;
						}

					}).catch(function (error) {

						CommonService.log('error', 'getLayouts()::axios', error);

					});
			},
			getMetroList(region_id) {

				if (typeof region_id == "undefined")
					region_id = null;

				return api.get('/common/metro', {
					params: {
						region_id: region_id
					}
				}).then((response) => {
					CommonService.log('debug', 'getMetro()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						return list;
					}

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getMetro()::axios', error);

				});
			},
     
			getTypes(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/types', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							if (section == 'objects')
								this.$store.commit('setObjectTypesList', list);

							if (section == 'to_metro')
								this.$store.commit('setToMetroTypesList', list);

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getTypes()::axios', error);

					});
				}
			},
			getList(section) {
				return api.get('/common/list', {
					params: {
						section: section
					}
				})
					.then((response) => {
            //console.log(response.data);
						CommonService.log('debug', 'getList()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let list = [];
							if (response.data.list) {
								Object.entries(response.data.list).forEach(([key, item]) => {
									list.push({
										value: item.id,
										name: item.name
									});
								});
							}
							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getList()::axios', error);

					});
			},
			getLists(sections) {
				return api.get('/common/lists', {
					params: {
						sections: sections
					}
				})
				.then((response) => {

          //console.log(response.data.lists);
					CommonService.log('debug', 'getLists()::axios', {response: response.data});

					return response.data.lists;
				}).catch(function (error) {

					CommonService.log('error', 'getLists()::axios', error);

					return null;
				});
			},
			setError(name, error) {

				CommonService.log('debug', 'setError()', {name, error});

				let errors = [];
				errors.push(error);

				if (!this.isEmpty(errors)) {
					if (!this.isEmpty(this.errors[name])) {
						this.errors[name].errors = errors;
					} else {
						this.errors[name] = {
							name: name,
							errors: errors
						};
					}

					this.$refs[name].valid = false;
					this.$refs[name].invalid = errors.join('<br/>');
				} else {
					this.$refs[name].valid = true;
					this.$refs[name].invalid = false;
				}
			},
			imageUploaded(section, images) {

				CommonService.log('debug', 'imageUploaded()', {section, images});

				let name = 'photos';
				if (section == 'img_plan')
					name = 'plans';

				setTimeout(() => {
					this.$refs[name].valid = true;
					this.$refs[name].invalid = false;
				}, 5000);
			},
			updateVariablesList(variables) {

				if (!this.isEmpty(variables))
					this.variables_list = variables;

				CommonService.log('debug', 'updateVariablesList()', variables);

			},
			icon(path, class_name, title, size) {
				return CommonService.buildIconImage(path, class_name, title, size);
			},
			addressTyped(value) {

				CommonService.log('debug', 'addressTyped()', value);

				if (this.country_id == 171) { // Подбор вариантов адреса (только для РФ)
					CommonService.debounce(this.getAddressVariants(value), 800);
				}
			},
      add_station_metro(){
        if(this.item.metro.stations){
          let stantion = {"object_id": this.item.id,
                          "id_metro": null,
                          "station": "",
                          "subway_time": null,
                          "subway_distance": 0
                        } 
          this.item.metro.stations.push(stantion);              
        }
      },
      deleteMetro(i) {
        if(!this.isEmpty(this.item.metro.stations)){
          this.item.metro.stations.splice(i,1);
        }
      },
      add_room(){
        if(this.item.object_room_types){
          let room = {"object_id": this.item.id,
                          "room_id": null,
                          "area": null,
                          
                        } 
          this.item.object_room_types.push(room);              
        }
      },
      deleteRooms(i){
        if(!this.isEmpty(this.item.object_room_types)){
          this.item.object_room_types.splice(i,1);
        }
      },
			setAndValidate(name, value, skip_advert_check) {

				let errors = [];
				let parent = null;
				let child = null;
				let parts = name.split('.', 2);
				if (parts.length == 2) {
					parent = parts[0];
					child = parts[1];
				}

				skip_advert_check = false;
				if (typeof skip_advert_check != "undefined")
					skip_advert_check = true;

				// Валидация значения
				if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
					/*if ('invalid' in this.$refs[name])
						this.$refs[name].invalid = false;*/

					if (this.$refs[name].inputLabel) {
						let label = this.$refs[name].inputLabel.replace(/:+$/, '');

						let is_skip = false;
						/*if (name == 'funnel_id' && value == 0)
							is_skip = true;*/

						if (this.$refs[name].required && !is_skip) {
							if (value) {
								if (this.$refs[name].required && value.toString() == '0')
									errors.push('Поле `' + label + '` обязательно к заполнению!');
								else if (this.$refs[name].required && this.isEmpty(value))
									errors.push('Поле `' + label + '` обязательно к заполнению!');
							} else {
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							}

							if (
								this.inArray(name, ['deal_type', 'avito_room_type_id', 'address.country_id', 'house_material_type', 'house_type']) &&
								value === 'Не выбрано') {
								errors.push('Поле `' + label + '` обязательно к заполнению!');
								value = null;
							}
						}

						if (name == 'metro.type' && this.isEmpty(value) && !this.isEmpty(this.item.metro.time)) {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}

						if (name == 'photos') {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}

						if (!this.isEmpty(errors)) {

							this.errors[name] = {
								name: name,
								label: label,
								errors: errors
							};

							this.$refs[name].valid = false;
							this.$refs[name].invalid = errors.join('<br/>');
						} else {
							this.$refs[name].valid = true;
							this.$refs[name].invalid = false;
						}
					}
				}

				// Проверки перед установкой значения
				if (name) {

					if (name == 'address.full' && typeof value === "string" && !this.isEmpty(value)) {
						value = value.replace(/^\d{6,},/g, ''); // чистим от почтового индекса (dadata.ru)
					}

					if (name == 'operation_type' && value == 1)
						this.item.lease_type = 2;
					else if (name == 'operation_type' && value !== 2)
						this.item.lease_type = null;

					if ((name == 'geo.lat' || name == 'geo.lng') && !this.isEmpty(value))
						value = parseFloat(value);

					if (name == 'object_type' && value == 4)
						this.item.commercial_type = 1;
					else if (name == 'object_type' && value !== 4)
						this.item.commercial_type = null;

					if (name == 'address.region_id') {
						//this.item.metro = null;
						//this.item.to_metro = null;
						this.metro_list = null;
						this.getMetroList(value).then(data => {

							if (!this.isEmpty(data))
								this.metro_list = data;
							else
								this.metro_list = null;

						});

						this.getNewBuildings(value).then(data => {
							if (!this.isEmpty(data))
								this.newbuildings_list = this.sortArray(data)
							else
								this.newbuildings_list = null;
						});
					}

					if (this.inArray(name, ['prices.amount', 'prices.amount_commission', 'prices.prep', 'prices.start', 'prices.fact', 'prices.price_avito'])) {
						if (typeof value === "string" && !this.isEmpty(value)) {
							value = parseInt(value.replace(/\s/g, ''));
						}
					}

					if (!skip_advert_check && this.item.id && (name == 'use_in_advert' && value == 0)) {
						let confirm = CommonService.getModal('addEditObjectConfirmRemoveFromAds');
						confirm.show();
					}
				}

				// Установка значения
				if (parent && child) {
					if (!this.isEmpty(this.item[parent])) {
						if (typeof (this.item[parent][child]) !== "undefined") {
							this.item[parent][child] = value;
						} else {
							this.item[parent] = {
								...{
									[child]: value
								},
								...this.item[parent]
							};
						}
					} else {
						this.item[parent] = {
							[child]: value
						};
					}
				} else if (parent == null && name) {
					/*if (name == 'fields') {
						return;
					} else {
						this.item[name] = value;
					}*/
					this.item[name] = value;
				} else {
					if (parent && child)
						this.item[parent][child] = null;
					else
						this.item[name] = null;
				}

				this.is_form_changed = true;

				CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });



				if (!this.isEmpty(errors))
					return false;

				return true;
			},
			validateAll(fields) {

				this.errors = [];
				let is_valid = false;
				let collection = CommonService.proxyToObject(fields);
				let invalid_input = null;
				let blocks = [
					'address',
					'variables',
					'areas',
					'parts_area',
					'cadastral',
					'building',
					'commerce',
					'features',
					'floors',
					'geo',
					'ivestor',
					'metro',
					'newbuildings',
					'prices',
				];

				for (const [name, value] of Object.entries(collection)) {

					let input_is_valid = true;
					if (typeof name !== "undefined") {
						if (name == 'fields' && !this.isEmpty(this.custom_fields_list)) {
							input_is_valid = this.$refs.customFields.validateAll();
						} else if (this.inArray(name, blocks)) {
							blocks.forEach((block_name) => {
								if (name == block_name) {

									let entries = [];
									if (!this.isEmpty(value))
										entries = Object.entries(value);

									for (const [field_name, field_value] of entries) {
										if (!this.setAndValidate(block_name + '.' + field_name, field_value, true)) {
											input_is_valid = false;
										}
									}
								}
							});
						} else {
							if (!this.setAndValidate(name, value, true)) {
								input_is_valid = false;
							}
						}
					}

					console.log('Validate: ' + name + ', is valid? - ' + input_is_valid.toString());
				}

				if (typeof Object.values(this.errors) == "object") {
					this.errors = CommonService.removeEmpty(Object.values(this.errors));
					if (typeof (this.errors[0]) !== "undefined") {
						if (!this.isEmpty(Object.values(this.errors)[0]['name'])) {
							let invalid_input = Object.values(this.errors)[0]['name'];
							if (invalid_input && typeof invalid_input !== "undefined") {
								let input = this.$refs[invalid_input].$el;
								if (input) {
									this.$nextTick(() => CommonService.scrollIntoView(input, 60));
								}
							}
						}
					}
				}

				is_valid = this.isEmpty(this.errors);

				CommonService.log('debug', 'validateAll()', {fields: collection, is_valid: is_valid, errors: this.errors});

				return is_valid;
			},

      changeId: function (servis){
        this.is_update_avito = true;
        
        let object_id = this.item.id;
        let id = 0;
        if(servis == 1){
          id = this.item.replace_avito_id;
        }
        if(servis == 2) {
          id = this.item.replace_cian_id;
        }
        if (this.item.id){
          api.post('/object/change_id',
          {
            object_id: object_id,
            servis: servis,
            id: id 
          }).then((response) => {
              console.log(response.data);
              if (response.status == 200 && response.data.success) {
                this.resultsModalState.state = 'success';
                if(servis == 1)
                  this.resultsModalState.title = 'ИД Авито успешно заменен!';
                if(servis == 2)
                  this.resultsModalState.title = 'ИД Циан успешно заменен!';

                
              }
            }).catch(function (error) {
              CommonService.log('error', 'changeId()::axios', error);
              
            });
          } else {
            this.resultsModalState.state = 'error';
            this.resultsModalState.title = 'Объект не сохранен в БД, пожалуйста, сохраниете объект и затем замените ИД';
          }
      },

			addEditObject: function () {
        this.is_update_avito = false;
				CommonService.log('debug', 'addEditObject()', this.item);

				if (this.validateAll(this.item)) {

					/*if (this.item.deal_type == 2)
						this.item.deal_type = 1
					else
						this.item.deal_type = 0*/

					this.is_form_changed = false;
					this.is_form_processed = true;

					api.post('/object/add', this.item, {
						xdebug: true
					}).then((response) => {

						if (process.env.NODE_ENV == "development")
							console.debug('addEditObject()::axios', {
								response: response.data
							});

						if (response.status == 200 && response.data.success) {

							this.$store.commit('resetFormsState', 'addObject');
							this.resultsModalState.state = 'success';

							if (this.item.id)
								this.resultsModalState.title = 'Объект был успешно сохранён!';
							else
								this.resultsModalState.title = 'Новый объект был успешно добавлен!';

							if (!this.isEmpty(response.data.object_id))
								this.item.id = response.data.object_id;

						} else {
							this.resultsModalState.state = 'error';

							if (this.item.id)
								this.resultsModalState.title = 'Ошибка сохранения объекта.';
							else
								this.resultsModalState.title = 'Ошибка добавления нового объекта.';

							if (!this.isEmpty(response.data.errors))
								this.resultsModalState.message = response.data.errors.join('<br/>');

						}

						this.is_form_processed = false;
					}).catch(function (error) {
						CommonService.log('error', 'addEditObject()::axios', error);
						this.is_form_processed = false;
					});
				}
			},
        },
        components: {
			Loader,
			//DateTimePicker,
			EmptyList,
			ResultsModal,
			InputRadio,
			InnerFieldsForm,
			ConfirmModal,
			ImageUploader,
			Editor,
			ToggleButtons,
			//InputRange,
			InputText,
			InputNumber,
			TextArea,
			//InputRadio,
			InputCheckbox,
			InputSelect,
			SelectBox,
			Switcher,
			GeoLocationModal,
			CustomFields,
      NewTaskModal
        },
        mounted() {
			if (this.object_id !== null) {

				CommonService.setPageTitle(this.title['edit']);

				if (this.restore_form) {

					let stored = this.$store.getters.formState['addObject'];
					CommonService.log('debug', 'getFormState', stored);

					if (typeof stored !== "undefined")
						this.item = stored;
          if (this.item.prices.currency) {
            switch (this.item.prices.currency) {
              case 'USD':
                this.item.prices.currency = 1
              break;
              case 'EUR':
                this.item.prices.currency = 2
              break;
              case 'RUB':
              case 'RUR':
                this.item.prices.currency = 0
              break;
            }
          }

					CommonService.log('debug', 'getFormState:client_id', this.client_id);
					if (!this.isEmpty(this.client_id)) {
						this.setAndValidate('owners', [Number(this.client_id)]);
					}
				} else {
					this.$store.commit('resetFormsState', 'addObject');
					this.getObject().then(data => {

						this.item = {...this.item, ...data};
            if (this.item.prices.currency) {
              switch (this.item.prices.currency) {
                case 'USD':
                  this.item.prices.currency = 1
                  break;
                case 'EUR':
                  this.item.prices.currency = 2
                  break;
                case 'RUB':
                case 'RUR':
                  this.item.prices.currency = 0
                  break;
              }
            }
						// Станиции метро, ЖК для региона объекта
						if (!this.isEmpty(this.region_id)) {
							this.getMetroList(this.region_id).then(data => {
								this.metro_list = data;
							});

							this.getNewBuildings(this.region_id).then(data => {
								this.newbuildings_list = this.sortArray(data)
							});
						}

						// Переменные для документов
						if (!this.isEmpty(this.item.variables)) {
							this.variables_list = this.item.variables;
							this.item.variables = {};
							Object.entries(this.variables_list).forEach(([key, option]) => {
								this.item.variables[key.toString()] = option.value;
							});
						}

						this.is_loading_process = false;
					});
				}
			} else {
				CommonService.setPageTitle(this.title['default']);

				if (this.restore_form) {

					let stored = this.$store.getters.formState['addObject'];
					CommonService.log('debug', 'getFormState', stored);

					if (typeof stored !== "undefined")
						this.item = stored;
          if (this.item.prices.currency) {
            switch (this.item.prices.currency) {
              case 'USD':
                this.item.prices.currency = 1
                break;
              case 'EUR':
                this.item.prices.currency = 2
                break;
              case 'RUB':
              case 'RUR':
                this.item.prices.currency = 0
                break;
            }
          }

					CommonService.log('debug', 'getFormState:client_id', this.client_id);
					if (!this.isEmpty(this.client_id)) {
						this.setAndValidate('owners', [Number(this.client_id)]);
					}
				} else {
					this.$store.commit('resetFormsState', 'addObject');
				}

				if (!this.isEmpty(this.$store.getters.userInfo)) {

					if (!this.isEmpty(this.$store.getters.userInfo.region_id))
						this.user_region_id = this.$store.getters.userInfo.region_id;

				}

				// Станиции метро, ЖК для региона объекта
				if (!this.isEmpty(this.region_id)) {
					this.getMetroList(this.region_id).then(data => {
						this.metro_list = data;
					});

					this.getNewBuildings(this.region_id).then(data => {
						this.newbuildings_list = this.sortArray(data)
					});
				}

				this.is_loading_process = false;
			}

      this.getListStatus().then(data => {
        this.status_list = data;
      });

      this.getAreaAnalyticsList().then(data => {
          this.area_analytics_list = data;
      });

			// Получаем клиента, который привязан как собственник
			if (!this.isEmpty(this.item.owners)) {
				this.getClientsList(this.item.owners).then(data => {
					this.clients_list = [...this.clients_list, ...data]
				});
			}

			if (!this.isEmpty(this.$store.getters.userInfo.watermark))
				this.item.add_watermark = true;

			if (!this.isEmpty(this.$props.clientId))
				this.getClientsList([this.$props.clientId]).then(data => { this.clients_list = [...this.clients_list, ...data] });
			else
				this.getClientsList().then(data => { this.clients_list = [...this.clients_list, ...data] });

			if (!this.isEmpty(this.$store.state.to_metro_list))
				this.to_metro_list = this.$store.state.to_metro_list;
			else
				this.getTypes('to_metro').then(data => { this.to_metro_list = data });

			if (!this.isEmpty(this.$store.state.regions_list))
				this.regions_list = this.$store.state.regions_list;
			else
				this.getRegions().then(data => { this.regions_list = data });

			this.getTags().then(data => { this.tags_list = data });

			let _this = this;
			this.getLists([
				'countries',
				'floor_materials',
				'building_types',
				'building_classes',
				'houseline_types',
				'building_categories',
				'entrance_types',
				'building_ventilations',
				'building_conditioning',
				'building_heating',
				'building_firefighting',
				'garages',
				'garages_types',
				'boxes_types',
				'commerce_status',
				'commerce_specifications',
				'commerce_infrastructures',
				'commerce_infrastructures2',
				'commerce_infrastructures3',
				'commerce_portal',
				'commerce_purpose',
				'commerce_land_categories',
				'commerce_permitted_use',
				'commerce_electricity',
				'commerce_gas',
				'commerce_water',
				'commerce_sewage',
				'commerce_driveways',
				'commerce_services',
				'legal_address',
				'sale_types',
				'decoration_types',
				'flat_types',
				'investor_types',

				'layouts',
				'wet_spots',
				'conditions',
				'access',
				'property_types',
				'rent_house_types',
				'wall_types',
				'house_wcs_types',
				'land_purposes_types',
				'shape_types',
				'plumbing_types',
				'gas_types',
				'electricity_types',
				'sewerage_types',
				'relief_types',
				'land_usage_types',
				'lease_deposit_types',
				'window_view_types',
				'renovation_types',
        'renovation_site',
				'bathroom_types',
				'hot_water_types',
				'parking_types',
				'parking_types1',
				'parking_types2',
				'parking_types3',
				'vat_types',
        'room_types',
        'mebel',

			]).then(data => {

				if (!_this.isEmpty(data)) {

					Object.entries(data).forEach(([section, list]) => {
						if (!_this.isEmpty(list)) {
							for (var i = 0; i < list.length; i++) {
								list[i].value = list[i]['id'];
								delete list[i].id;
							}

							if (typeof _this[section + '_list'] !== "undefined") {
								if (this.inArray(section, [
									'commerce_purpose',
									'commerce_land_categories',
									'commerce_permitted_use'
								])) {
									_this[section + '_list'] = this.sortArray(list);
								} else {
									_this[section + '_list'] = list;
								}
							} else {
								CommonService.log('error', 'getLists()', 'Not found variable by name `' + section + '_list`');
							}
						}
					});
				}

				return _this;
			});


			/*fields: response.data.fields,
			blocks: response.data.blocks,
			types: response.data.types,*/

      this.getCustomDopFields().then(data => {
				this.custom_fields_dop_list = data;
				if (!this.isEmpty(this.custom_fields_dop_list)) {

					if (this.isEmpty(this.item.fields) || typeof this.item.fields !== "object")
						this.item.fields = [];

					for (const field of this.custom_fields_dop_list.fields) {
						let field_name = field.name;
						let field_value = field.value;
						if (typeof this.item.fields[field_name] == "undefined") {
							this.item.fields[field_name] = field_value;
						}
					}
				}
			});

			this.getCustomFields().then(data => {
				this.custom_fields_list = data;
				if (!this.isEmpty(this.custom_fields_list)) {

					if (this.isEmpty(this.item.fields) || typeof this.item.fields !== "object")
						this.item.fields = [];

					for (const field of this.custom_fields_list.fields) {
						let field_name = field.name;
						let field_value = field.value;
						if (typeof this.item.fields[field_name] == "undefined") {
							this.item.fields[field_name] = field_value;
						}
					}
				}
			});
        },
		watch: {
			object_id: function() {
				return this.objectId;
			},
			region_id: function(value) {
				this.item.address.region_id = value;
			},
			search_query(value, oldValue) {

				if (!oldValue || typeof oldValue == "undefined")
					oldValue = '';

				if ((value !== oldValue && (value.length >= 3 || oldValue.length >= 3)) || value.length == 0) {
					this.getClientsList().then(data => {
						this.clients_list = data;
					});
				}
			},
			clients_offset(value, oldValue) {
				if (value !== oldValue && value != 0) {
					this.getClientsList().then(data => {
						this.clients = [...this.clients, ...data];
					});
				}
			},
			newbuildings_query(value, oldValue) {

				if (!oldValue || typeof oldValue == "undefined")
					oldValue = '';

				if ((value !== oldValue && (value.length >= 3 || oldValue.length >= 3)) || value.length == 0) {
					this.getNewBuildings(this.region_id, this.newbuildings_offset, value).then(data => {
						this.newbuildings_list = data;
						this.newbuildings_list = this.sortArray(this.newbuildings_list);
					});
				}
			},
			newbuildings_offset(value, oldValue) {
				if (value !== oldValue && value != 0) {
					this.getNewBuildings(this.region_id, value, this.newbuildings_query).then(data => {
						this.newbuildings_list = [...this.newbuildings_list, ...data];
						this.newbuildings_list = this.sortArray(this.newbuildings_list);
					});
				}
			},
			address_full(value, oldValue) {
				if (oldValue === null && this.item.address.full !== null) {
				  return;
        }
			  if (value !== oldValue && this.isEmpty(value) != null) {
					CommonService.debounce(this.getSuggestAddress(value), 1000);
				}
			},
		},
		computed: {
			user() {

				if (!this.isEmpty(this.$store.getters.userInfo))
					return this.$store.getters.userInfo;

				return {};
			},
			is_owner_required() {
				return (this.user.agency_id == 13386);
			},
			geo_lat: {
				get() {
					if (!this.isEmpty(this.item.geo.lat))
						return (!isNaN(parseFloat(this.item.geo.lat))) ? parseFloat(this.item.geo.lat) : 0;
					else
						return null;
				},
				set(value) {
					value = parseFloat(value);
					this.item.geo.lat = value;
				}
			},
			geo_lng: {
				get() {
					if (!this.isEmpty(this.item.geo.lng))
						return (!isNaN(parseFloat(this.item.geo.lng))) ? parseFloat(this.item.geo.lng) : 0;
					else
						return null;
				},
				set(value) {
					value = parseFloat(value);
					this.item.geo.lng = value;
				}
			},
			owners() {

				let owners = this.item.owners ?? [];
				if (!this.isEmpty(this.client_id))
					owners = [...owners, ...[Number(this.client_id)]];

				owners = CommonService.uniqueArray(owners);
				return owners;
			},
			infrastructures_list() {
				if (!this.isEmpty(this.item.commercial_type)) {

					if (this.item.commercial_type == 7)
						return this.commerce_infrastructures2_list // автомойка - шиномонтаж
					else if (this.item.commercial_type == 2 || this.item.commercial_type == 4)
						return this.commerce_infrastructures3_list; // буфет, гостинница
				}

				return this.commerce_infrastructures_list
			},
			country_id: {
				get(_this) {
					let country_id = 171;
					if (!_this.isEmpty(_this.item.address.country_id))
						country_id = _this.item.address.country_id;

					_this.item.address.country_id = country_id;
					return country_id;
				},
				set(value) {
					value = parseInt(value.trim());
				}
			},
			region_id() {

				let region_id = null;
				if (!this.isEmpty(this.item.address))
					region_id = this.item.address.region_id;

				if (region_id == 80 || region_id == 53)
					region_id = 1;
				else if (region_id == 81 || region_id == 50)
					region_id = 2;

				if (!this.isEmpty(this.user_region_id))
					region_id = this.user_region_id;

				return region_id;
			},
			address_full() {
				if (!this.isEmpty(this.item.address.full))
					return this.item.address.full;
				else
					return null;
			},
      currency_list() {
        return [
          {value: 0, name: "₽"},
          {value: 1, name: "$"},
          {value: 2, name: "€"},
        ]
      },
      month_list() {
        return [
          {value: 1, name: "1 мес"},
          {value: 2, name: "2 мес"},
          {value: 3, name: "3 мес"},
          {value: 4, name: "4 мес"},
          {value: 5, name: "5 мес"},
          {value: 6, name: "6 мес"},
          {value: 7, name: "7 мес"},
          {value: 8, name: "8 мес"},
          {value: 9, name: "9 мес"},
          {value: 10, name: "10 мес"},
          {value: 11, name: "11 мес"},
          {value: 12, name: "12 мес"},
        ]
      },
      kitchen_type() {
        return [
          {value: 1, name: "Открытая"},
          {value: 2, name: "Изолированная"},
        ]
      },
      migration_registration_list() {
        return [
          {value: 1, name: "Да"},
          {value: 2, name: "Нет"},
          {value: 3, name: "Обсуждается"}
        ]
      }
		}
    };
</script>
